import { useState, useMemo, useEffect } from 'react';
import { usePoolFactoryContract } from '@/hooks/useContract';
import { useSingleResult } from '../hooks';
import { useTokenContract } from '@/hooks/useContract';
import { poolFactoryType } from '@/constants';
import { useActiveWeb3React } from '@/hooks';
import { useFarmsMessage } from '@/newPages/hooks';
import { toast } from 'react-toastify';
import { maxUint256Value } from '@/constants';
import BigNumber from 'bignumber.js';
import { useParams } from 'react-router-dom';
import { poolList } from '@/newPages/1230';
import { useTradeExactIn } from '@/hooks/Trades';
import { tryParseAmount } from '@/state/swap/hooks';
// import { Modal } from 'antd';
import { BLOCKS_PER_YEAR, PRECISION } from '@/constants';
import { useCurrency } from '@/hooks/Tokens';
import { getFarmQuoteToken } from '@/newPages/farms/component/tvl';
import { useToken } from '@/new_hooks/Tokens';
import { digitalPrecision } from '@/new_hooks/utils';
import { ethers } from 'ethers';
import { NETWORK_CHAIN_ID } from '@/connectors';

// const { confirm } = Modal;

export const useGetTokenType = () => {
  const { TokenName }: { TokenName: poolFactoryType } = useParams();
  return TokenName;
};

type usePoolFactoryType = {
  isOpen: boolean;
  openPool: () => any;
  loading: boolean;
  deposit: (
    pid: number,
    type: number,
    amount: string,
    fn?: () => any,
    amountNum?: number,
  ) => any;
  withdraw: (pid: number, orderId: number, amount?: string) => any;
  stakingToken: string;
};
export const usePoolFactory = (): usePoolFactoryType | null => {
  const TokenName = useGetTokenType();
  const { account } = useActiveWeb3React();
  const PoolFactoryContract = usePoolFactoryContract(TokenName);

  const stakingToken = useSingleResult(PoolFactoryContract, 'stakingToken'); // 质押 ToToken 地址

  const tokenContract = useTokenContract(stakingToken ?? undefined);

  const allowance = useSingleResult(tokenContract, 'allowance', [
    account,
    PoolFactoryContract?.address,
  ])?.toString();

  const stakingTokenIsNative = useSingleResult(
    PoolFactoryContract,
    'stakingTokenIsNative',
  ); // ToToken 是否为稳定币(只有 FIBO 链的 wfibo 为 true

  const [loading, setLoading] = useState(false);
  const { orderMessage: Message, warning: notify } = useFarmsMessage();

  const orderMessage = (hash: string, fn?: () => any) => {
    Message(hash, () => {
      setLoading(false);
      fn?.();
    });
  };

  const fn_ = async (
    action: string,
    parms: any,
    fn?: () => any,
    Contract = PoolFactoryContract,
  ) => {
    if (!Contract) return;

    setLoading(true);
    const toastId = toast.loading('上链中...');
    try {
      console.log('try');

      const res = await Contract[action](...parms);
      if (res?.hash) {
        toast.done(toastId);
        orderMessage(res.hash, fn);
      }
    } catch (error) {
      console.log('catch');

      setLoading(false);
      setTimeout(() => toast.done(toastId), 1000);
    }
  };

  // 授权
  const openPool = async () => {
    fn_(
      'approve',
      [PoolFactoryContract?.address, maxUint256Value],
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      tokenContract,
    );
  };

  // 质押
  const deposit = async (
    pid: number,
    type: number,
    amount: string,
    fn?: () => any,
    amountNum?: number,
  ) => {
    const params: any[] = [pid, type, amount];
    if (amountNum) {
      const options = { value: ethers.utils.parseEther(amountNum?.toString()) };
      params.push(options);
    }

    try {
      fn_('deposit', params, fn);
    } catch (error) {}
  };

  // 赎回 ToToken
  const withdraw = async (
    pid: number,
    orderId: number,
    singleAmount?: string,
  ) => {
    const params = [pid, orderId, singleAmount ? singleAmount : 0];
    fn_('withdraw', params);
  };

  // allowance ===0 未授权（授权） !==0 代币映射
  return useMemo(() => {
    if (!allowance || !PoolFactoryContract) return null;
    return {
      // isOpen: stakingTokenIsNative ? stakingTokenIsNative : allowance !== '0',
      allowance,
      isOpen: allowance !== '0' && allowance !== null,
      openPool,
      loading,
      deposit,
      withdraw,
      TokenName,
      stakingToken,
      stakingTokenIsNative,
    };
  }, [
    stakingTokenIsNative,
    allowance,
    account,
    loading,
    deposit,
    openPool,
    withdraw,
    TokenName,
    stakingToken,
  ]);
};

export const useGetPoolList = () => {
  const tokenNameType = useGetTokenType();
  const PoolFactoryContract = usePoolFactoryContract(tokenNameType);

  const { library, account, chainId } = useActiveWeb3React();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<any[]>([]);
  const [BlockNum, setBlockNum] = useState<number | null>();
  const getBlock = async () => {
    const res = await library?.getBlockNumber();
    setBlockNum(res);
  };
  useEffect(() => {
    getBlock();
  }, []);

  useEffect(() => {
    (async () => {
      if (!PoolFactoryContract || chainId !== NETWORK_CHAIN_ID) return;
      if (!BlockNum) return;
      setLoading(true);
      const list = poolList[tokenNameType];
      const newList = [];
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        console.log(PoolFactoryContract, 'PoolFactoryContract');

        const poolInfo = await PoolFactoryContract?.poolInfo(element.pid);
        console.log(poolInfo, 'poolInfo');

        const userActivationPool = await PoolFactoryContract?.userActivationPool(
          account,
          element?.pid,
        );
        const userReward = await PoolFactoryContract?.userReward(
          element?.pid,
          account,
        );

        const obj = {
          ...element,
          pid: element.pid,
          poolInfo: poolInfo,
          endTime: BigNumber(poolInfo?.startBlock?.toString())
            .plus(poolInfo?.totalBlock?.toString())
            .minus(BlockNum?.toString())
            .toString(),
          isUserActivationPool: userActivationPool,
          userReward: userReward.toString(),
          // poolAmount,
        };
        newList.push(obj);
      }
      setLoading(false);
      setData(newList);
    })();
  }, [BlockNum, tokenNameType, PoolFactoryContract]);

  return useMemo(() => {
    return {
      list: data,
      loading,
    };
  }, [PoolFactoryContract, data, loading]);
};

export const useTvl = (pid: number) => {
  const TokenName = useGetTokenType();
  const PoolFactoryContract = usePoolFactoryContract(TokenName);
  const stakingToken = useSingleResult(PoolFactoryContract, 'stakingToken'); // 质押 ToToken 地址

  const poolInfo = useSingleResult(PoolFactoryContract, 'poolInfo', [pid]);
  const poolAmount = useSingleResult(PoolFactoryContract, 'poolAmount', [pid]); // 总质押⾦额

  const {
    value: { decimals },
  } = useToken(stakingToken ?? '');

  // ======== TVL ===============
  const pledgeToken = useCurrency(stakingToken); // 质押币
  const pledgeTokenAmount = tryParseAmount('1', pledgeToken ?? undefined); // 1枚
  // 换算token对usdt的价格
  const bestTradePledgeTokenAmount = useTradeExactIn(
    ...getFarmQuoteToken(pledgeTokenAmount),
  ); // 交易对

  console.log(
    poolAmount,
    'poolAmount',
    poolAmount?.toString(),
    bestTradePledgeTokenAmount?.executionPrice.toSignificant(),
    poolAmount?.toString(),
  );

  const tvl =
    poolAmount && bestTradePledgeTokenAmount
      ? BigNumber(digitalPrecision(poolAmount?.toString(), decimals, true) ?? 0)
          .times(
            bestTradePledgeTokenAmount?.executionPrice.toSignificant() ?? 0,
          )
          .toString()
      : 0;

  // ======================

  // ========== APR ============
  const rewardToken = useCurrency(poolInfo?.rewardToken); // 奖励币
  const rewardTokenAmount = tryParseAmount('1', rewardToken ?? undefined); // 1枚
  const bestTraderewardToken = useTradeExactIn(
    ...getFarmQuoteToken(rewardTokenAmount),
  ); // 交易对

  const blockRewards_num = BigNumber(poolInfo?.totalRewards.toString() / 1e18)
    .div(poolInfo?.totalBlock.toString())
    ?.toString(); // 块奖励

  const TotalAmount = BigNumber(blockRewards_num)
    .times(BLOCKS_PER_YEAR)
    .times(bestTraderewardToken?.executionPrice.toSignificant() ?? 0)
    .toString();

  const apr =
    TotalAmount && tvl
      ? BigNumber(TotalAmount)
          .div(tvl)
          .times('100')
          .toFixed(PRECISION)
      : '0';

  return useMemo(() => {
    if (!apr || !tvl)
      return {
        apr: false,
        tvl: false,
      };
    return {
      tvlNum: tvl,
      aprNum: apr,
      apr: apr === 'Infinity' ? '0' : apr + ' %',
      tvl: tvl + ' $',
    };
  }, [tvl, apr]);
};

type useGetpoolInfo = {
  poolInfo: any;
  endTime: string;
  isUserActivationPool: boolean;
};
// 模块item hooks
export const useGetpoolInfo = (pid: number): useGetpoolInfo | null => {
  const { library, account } = useActiveWeb3React();
  const tokenNameType = useGetTokenType();
  const PoolFactoryContract = usePoolFactoryContract(tokenNameType);
  const poolInfo = useSingleResult(PoolFactoryContract, 'poolInfo', [pid]);

  const userActivationPool = useSingleResult(
    PoolFactoryContract,
    'userActivationPool',
    [account, pid],
  );

  //
  const [BlockNum, setBlockNum] = useState<number | null>();

  const getBlock = async () => {
    const res = await library?.getBlockNumber();
    setBlockNum(res);
  };
  useEffect(() => {
    getBlock();
  }, []);

  return useMemo(() => {
    if (!BlockNum || !poolInfo || userActivationPool === null) return null;
    return {
      poolInfo,
      endTime: BigNumber(poolInfo?.startBlock?.toString())
        .plus(poolInfo?.totalBlock?.toString())
        .minus(BlockNum.toString())
        .toString(),
      isUserActivationPool: userActivationPool,
    };
  }, [poolInfo, BlockNum, userActivationPool]);
};
