import { ethers } from 'ethers';
import { BigNumber } from 'bignumber.js';
import BigNumberJs from 'bignumber.js';
import { PRECISION } from '@/constants';

export const digitalPrecision = (
  num: string | number,
  decimals: number,
  isDiv?: boolean, // 默认乘
) => {
  // 除法。 高精度小数转换阿拉伯数字
  if (!num) {
    return '';
  }
  if (isDiv) {
    return BigNumberJs(num.toString())
      .div(Math.pow(10, decimals))
      .toFixed(PRECISION)
      .toString();
  } else {
    // 默认转高精度小数
    return BigNumberJs(num.toString())
      .times(Math.pow(10, decimals))
      .toFixed(0);
  }
};

type dataType = Record<string, any>;
export type MethodArg = dataType | string | number | BigNumber;
export interface ReturnState {
  readonly value: any; // MethodArg | undefined;
  readonly loading: boolean;
  readonly error: boolean;
  [key: string]: any;
}

const INVALID_CALL_STATE: ReturnState = {
  value: undefined,
  loading: false,
  error: false,
};
// 状态
export function toReturnState(
  value: MethodArg | undefined = undefined,
  methodName?: string,
): ReturnState {
  if (!value) return INVALID_CALL_STATE;

  const obj_data = Object.entries(value)
    .map(item => item[1])
    .some(item => (item ?? '') !== ''); // (item ?? '') !== '' // 判断是否是对象，并且属性是否有值[兼容字符串等]

  if (value) {
    const data: ReturnState = {
      loading: obj_data,
      error: false,
      value: ethers.BigNumber.isBigNumber(value) ? value.toString() : value,
    };
    if (methodName) {
      data[methodName] = data.value;
    }
    return data;
  }

  return {
    ...INVALID_CALL_STATE,
    error: true,
  };
}
