import { useState, useEffect, useMemo } from 'react';
import { useActiveWeb3React } from '@/hooks';
import { useTokenContract } from '@/hooks/useContract';
import { BigNumber } from 'bignumber.js';
import { Contract } from '@ethersproject/contracts';
import { masterChef as MASTERCHEF } from '@/constants';
import { toast } from 'react-toastify';
import { useAddPopup } from '@/state/application/hooks';
import { useSelector } from 'react-redux';
import { useSetFarmsHash } from '@/state/farms/reducer';

// returns a function that allows adding a popup
function useGitHash(): () => any {
  return useSelector((state: any) => {
    return state.farms.hashList;
  });
}

// 单个调用结果
export function useSingleResult(
  contract: Contract | null | undefined,
  methodName: string, // 合约名
  params?: any, // 参数
  relyOn?: any, // 依赖
): any {
  const { account, chainId } = useActiveWeb3React();
  const [data, setData] = useState<any>(null);
  const hashList = useGitHash();
  const fragment = useMemo(
    () => contract?.interface?.getFunction(methodName.trim()),
    [contract, methodName],
  );

  const getResult = async () => {
    if (!fragment) return;
    (async () => {
      const newParams =
        params instanceof Array
          ? params
          : (params ?? '') !== ''
          ? [params]
          : [];
      try {
        const lpProAResult = await contract?.[methodName](...newParams);
        setData(lpProAResult);
      } catch (error) {}
    })();
  };

  useEffect(() => {
    getResult();
  }, [contract, hashList.length, params ? relyOn : null]);

  return useMemo(() => {
    if (!chainId || !account) return undefined;

    if (!contract) return null;
    return data;
  }, [data, chainId, account, contract, hashList.length, relyOn]);
}

// message
export const useFarmsMessage = () => {
  const { library } = useActiveWeb3React();
  const addPopup = useAddPopup();
  const SetFarmsHash = useSetFarmsHash();
  // message
  const orderMessage = (hash: string, fn?: () => any) => {
    const toastId = toast.loading('交易确认中...');
    library?.waitForTransaction(hash).then(() => {
      toast.done(toastId);
      // hash地址提示
      addPopup({
        txn: {
          hash: hash,
          success: !!hash,
          summary: '链上已经确认',
        },
      });
      SetFarmsHash(hash);
      fn?.();
    });
  };

  const notify = (msg: string) =>
    toast.warning(msg, { position: 'top-center', autoClose: 2200 });
  return {
    orderMessage,
    warning: notify,
    toast: toast,
  };
};

// 价格
export const useBalance = (token: string) => {
  const { account, chainId } = useActiveWeb3React();
  const tokenContract = useTokenContract(token); // lp合约
  const balanceOf = useSingleResult(tokenContract, 'balanceOf', [
    account,
  ])?.toString(); // 用户的余额
  const lpBalanceOf = useSingleResult(tokenContract, 'balanceOf', [
    MASTERCHEF,
  ])?.toString(); // lp总量
  const decimals = useSingleResult(tokenContract, 'decimals'); // 精度

  return useMemo(() => {
    if (!account || !chainId) {
      return undefined;
    }
    if (!balanceOf || !decimals || !lpBalanceOf) {
      return {};
    }
    return {
      Balance: BigNumber(balanceOf)
        .div(Math.pow(10, decimals))
        .toFixed(),
      balanceOf,
      decimals,
      token,
      lpBalanceOf,
    };
  }, [account, chainId, balanceOf, decimals, lpBalanceOf, token]);
};
