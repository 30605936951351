import styled, { createGlobalStyle } from 'styled-components';
import { primaryColor } from './index';
export default createGlobalStyle`
// 悬浮小手
  .cursor {
    cursor: pointer !important;
    &:hover {
      opacity: 0.7 !important;
    }
  }
  .flex{
    display: flex;
  }

  .flex-wrap {
   flex-wrap: wrap;
  }
  .flex-item-center {
    display: flex;
    align-items: center;
  };
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  };

  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  };

  .flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .flex-cloumn-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  /* 覆盖antd  css*/
  .ant-switch-checked{
    background-color: ${primaryColor};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),.ant-btn-primary{
    background-color: ${primaryColor}!important;
    border-color: ${primaryColor}!important;
  }
  .ant-slider-track{
    background-color: ${primaryColor}!important;
  }

  /* 适配 */
  /* html {
    font-size: 26.6666666666666667vw;
    margin: 0 auto;
    body {
      // 重置字体大小
      font-size: 0.14rem;
    }
  } */

`;
