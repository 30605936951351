import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { darken, lighten } from 'polished';
import React, { useMemo, useRef } from 'react';
import { Activity } from 'react-feather';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { NetworkContextName } from '@/constants';
import useENSName from '@/hooks/useENSName';
import { useHasSocks } from '@/hooks/useSocksBalance';
import {
  isTransactionRecent,
  useAllTransactions,
} from '@/state/transactions/hooks';
import { TransactionDetails } from '@/state/transactions/reducer';
import { addressConvert } from '@/utils/fbAddress';
import { ButtonSecondary } from '../Button';
import Loader from '../Loader';
import { RowBetween } from '../Row';
import WalletModal from '../WalletModal';
import wallet_img from '@/assets/images/icon/wallet_icon.png';
import lower_img from '@/assets/images/icon/botton.png';
import { MenuFlyout } from '@/components/Settings';
import {
  useUserMenuOpen,
  useUsertModalToggle,
  useWalletModalToggle,
} from '@/state/application/hooks';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import AccountDetails from '@/components/AccountDetails';
import { WALLET_VIEWS } from '@/components/WalletModal';

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }
`;
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
`;

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  background-color: ${({ theme }) => theme.primary4};
  border: none;
  color: ${({ theme }) => theme.primaryText1};
  font-weight: 500;

  :hover,
  :focus {
    border: 1px solid ${({ theme }) => darken(0.05, theme.primary4)};
    color: ${({ theme }) => theme.primaryText1};
  }

  ${({ faded }) =>
    faded &&
    css`
      background-color: ${({ theme }) => theme.primary5};
      border: 1px solid ${({ theme }) => theme.primary5};
      color: ${({ theme }) => theme.primaryText1};

      :hover,
      :focus {
        border: 1px solid ${({ theme }) => darken(0.05, theme.primary4)};
        color: ${({ theme }) => darken(0.05, theme.primaryText1)};
      }
    `}
`;

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background-color: ${({ pending, theme }) =>
    pending ? theme.primary1 : theme.bg2};
  border: 1px solid
    ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg3)};
  color: ${({ pending, theme }) => (pending ? theme.white : theme.text1)};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ pending, theme }) =>
      pending ? darken(0.05, theme.primary1) : lighten(0.05, theme.bg2)};

    :focus {
      border: 1px solid
        ${({ pending, theme }) =>
          pending ? darken(0.1, theme.primary1) : darken(0.1, theme.bg3)};
    }
  }
  height: 32px;
  padding: 0;
  padding-right: 13px;
  margin: 0;
  margin-left: 8px;
  border: none;
  ${({ theme, pending }) => theme.mediaWidth.upToMedium`
    padding-right:  ${pending ? '8px' : '5px'};
  `}
  box-shadow: rgb(0 0 0 / 10%) 0px -2px 0px inset;
  display: flex;
  justify-content: space-between;
`;

const ContentAdderss = styled.div`
  width: 96px;
  overflow: hidden;
  transition: width 0.5s;
  ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 0;
  `}
`;

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`;

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`;

const StatusIcon = styled.img`
  width: 21px;
`;
const IconBox = styled.div`
  border: 1.5px solid #349084;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LowerIcon = styled.img`
  width: 18px;
`;

const MenuFlyoutView = styled(MenuFlyout)`
  top: 3.4rem;
  overflow: hidden;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 17rem;
    right: .3rem;
  `};
`;

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime;
}

const SOCK = (
  <span
    role="img"
    aria-label="has socks emoji"
    style={{ marginTop: -4, marginBottom: -4 }}
  >
    🧦
  </span>
);

type Web3StatusInnertType = {
  onClick: () => void;
};
function Web3StatusInner({ onClick }: Web3StatusInnertType) {
  const { t } = useTranslation();
  const { account, error } = useWeb3React();

  const { ENSName } = useENSName(account ?? undefined);

  const allTransactions = useAllTransactions();

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions
    .filter(tx => !tx.receipt)
    .map(tx => tx.hash);

  const hasPendingTransactions = !!pending.length;
  const hasSocks = useHasSocks();
  const toggleWalletModal = useWalletModalToggle();
  // const open = useUserMenuOpen();
  if (account) {
    return (
      <Web3StatusConnected
        id="web3-status-connected"
        pending={hasPendingTransactions}
        onClick={onClick}
      >
        {hasPendingTransactions ? (
          <div style={{ marginLeft: '8px' }} className="flex-item-center">
            <Loader stroke="white" size="18px" />
          </div>
        ) : (
          <IconBox>
            <StatusIcon src={wallet_img} />
          </IconBox>
        )}

        <ContentAdderss>
          {hasPendingTransactions ? (
            <RowBetween>
              <Text>{pending?.length} Pending...</Text>
            </RowBetween>
          ) : (
            <>
              {hasSocks ? SOCK : null}
              <Text>{ENSName || addressConvert(account).rowfibo}</Text>
            </>
          )}
        </ContentAdderss>
        {!hasPendingTransactions && <LowerIcon src={lower_img} alt="" />}
      </Web3StatusConnected>
    );
  } else if (error) {
    return (
      <Web3StatusError onClick={onClick}>
        <NetworkIcon />
        <Text>
          {error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}
        </Text>
      </Web3StatusError>
    );
  } else {
    return (
      <Web3StatusConnect
        id="connect-wallet"
        onClick={toggleWalletModal}
        faded={!account}
      >
        <Text>{t('Connect Wallet')}</Text>
      </Web3StatusConnect>
    );
  }
}
const AccountElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  :focus {
    border: 1px solid blue;
  }
`;

export default function Web3Status() {
  const { active, account } = useWeb3React();
  const contextNetwork = useWeb3React(NetworkContextName);
  const { ENSName } = useENSName(account ?? undefined);

  const allTransactions = useAllTransactions();
  // const [open, setOpen] = useState<boolean>(false);
  const node = useRef<HTMLDivElement>();
  const open = useUserMenuOpen();
  const setOpen = useUsertModalToggle();
  useOnClickOutside(node, open ? setOpen : undefined);

  const toggleWalletModal = useWalletModalToggle();

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions
    .filter(tx => !tx.receipt)
    .map(tx => tx.hash);
  const confirmed = sortedRecentTransactions
    .filter(tx => tx.receipt)
    .map(tx => tx.hash);

  if (!contextNetwork.active && !active) {
    return null;
  }

  return (
    <AccountElement style={{ pointerEvents: 'auto' }} ref={node as any}>
      <Web3StatusInner onClick={setOpen} />
      {open && (
        <MenuFlyoutView>
          <AccountDetails
            toggleWalletModal={setOpen}
            pendingTransactions={pending}
            confirmedTransactions={confirmed}
            openOptions={toggleWalletModal}
          />
        </MenuFlyoutView>
      )}

      <WalletModal
        ENSName={ENSName ?? undefined}
        pendingTransactions={pending}
        confirmedTransactions={confirmed}
        key={WALLET_VIEWS.OPTIONS}
      />
    </AccountElement>
  );
}
