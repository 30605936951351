import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AccordionList from './component/AccordionList';
import { Switch, Radio } from 'antd';
import { useParams } from 'react-router-dom';
import { poolFactoryType } from '@/constants';
import { ListWrap } from '@/newPages/farms/list';
import HeaderTab from '@/newPages/farms/component/headerTab';
import { useGetPoolList } from './hooks';
import { ListView } from '@/newPages/farms/list';

export const PoolNavigation = () => {
  const { TokenName }: { TokenName: poolFactoryType } = useParams();
  const { t } = useTranslation();
  const data = [
    {
      pathname: `/pool/${TokenName}`,
      name: `${TokenName?.toLocaleUpperCase()} ${t('Zone')}`,
    },
    {
      pathname: `/pool/${TokenName}/historicalBill`,
      name: t('historical bill'),
    },
  ];
  return <HeaderTab data={data} />;
};

const RadioGroup = styled(Radio.Group)`
  display: flex;
  background-color: #fff;
  overflow: hidden;
  border-radius: 53px;
  background-color: ${({ theme }) => theme.primary5};
  border: 1px solid ${({ theme }) => theme.primary1};
  margin-right: 15px;
  .ant-radio-button-wrapper {
    background-color: transparent;
    border: none;
    width: 93px;
    text-align: center;
  }
  .ant-radio-button-wrapper::before {
    display: none;
  }
  .ant-radio-button-wrapper-checked {
    background-color: ${({ theme }) => theme.primary1} !important;
    border-radius: 53px;
  }
`;

const Index = () => {
  const { TokenName }: { TokenName: poolFactoryType } = useParams();
  const { t } = useTranslation();
  const [isMy, setIsMy] = useState<boolean>(false);
  const [ActiveKey, setActiveKey] = useState<string>('start');

  const { list, loading }: any = useGetPoolList();

  const screen = (data: any[]) => {
    let dataList = [];
    if (isMy) {
      dataList = data.filter(item => item.isUserActivationPool);
    } else if (ActiveKey === 'start') {
      dataList = data.filter(item => Number(item?.endTime) > 0);
    } else if (ActiveKey === 'end') {
      dataList = data.filter(item => Number(item?.endTime) < 0);
    }
    return dataList;
  };

  return (
    <div style={{ width: '100%' }}>
      <PoolNavigation />
      <ListWrap>
        <div className="banner-main">
          <div className="title">
            {TokenName.toLocaleUpperCase()} {t('Zone')}
          </div>
          <div className="introduce">
            {t('Pledge , mine tokens', {
              token: TokenName.toLocaleUpperCase(),
            })}
          </div>
        </div>
        <div className="flex-item-center" style={{ marginBottom: '22px' }}>
          <RadioGroup
            buttonStyle="solid"
            onChange={e => setActiveKey(e.target.value)}
            value={ActiveKey}
          >
            <Radio.Button value="start">{t('real time')}</Radio.Button>
            <Radio.Button value="end">{t('over')}</Radio.Button>
          </RadioGroup>
          <Switch checked={isMy} onChange={setIsMy} />
          &nbsp; {t('Only show what I pledged')}
        </div>
        <ListView
          dataSource={screen(list)}
          renderItem={(item: any) => (
            <div>
              <AccordionList data={item} key={item.pid} />
            </div>
          )}
          loading={loading}
        />
      </ListWrap>
    </div>
  );
};
export default Index;
