import { useMemo } from 'react';
import { useTokenContract } from '@/hooks/useContract';
import { toReturnState, ReturnState } from './utils';
import { BigNumber } from 'bignumber.js';
import { useActiveWeb3React as useWeb3Provider } from '@/hooks';
import { useSingleResult } from '@/newPages/hooks';

// TODO: 查找token（用户合约可以查询token地址的信息等。本地有信息则本地返回）
export function useToken(tokenAddress?: string): ReturnState {
  const TokenContract = useTokenContract(tokenAddress);
  const symbol = useSingleResult(TokenContract, 'symbol')?.toString();
  const decimals = useSingleResult(TokenContract, 'decimals')?.toString();
  const name = useSingleResult(TokenContract, 'name')?.toString();

  return useMemo(() => {
    const data = {
      symbol,
      decimals,
      name,
      address: tokenAddress,
    };
    return toReturnState(data);
  }, [symbol, decimals, name, tokenAddress]);
}

// token余额
export const useCurrencyBalances = (tokenAddress?: string): ReturnState => {
  const { account } = useWeb3Provider();

  const TokenContract = useTokenContract(tokenAddress ?? undefined);
  const decimals = useSingleResult(TokenContract, 'decimals')?.toString();
  const balanceOf = useSingleResult(
    TokenContract,
    'balanceOf',
    account ? [account] : [],
  )?.toString();

  return useMemo(() => {
    const data = {
      balanceOf,
      balances: balanceOf
        ? BigNumber(balanceOf)
            .div(Math.pow(10, decimals))
            .toFixed()
        : '',
    };

    return toReturnState(data);
  }, [decimals, balanceOf]);
};
