import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonGray } from '@/components/Button';
import InvitationModal from './invitationModal';
import { useWalletModalToggle } from '@/state/application/hooks';
import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';
import add_user_img from '@/assets/images/icon/add_user.png';

export const Btn = styled(ButtonGray)`
  height: 32px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.primary1};
  white-space: nowrap;
  margin: 0 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 26px;
    background: #EFF5F5;
    font-size: 16px;
    margin:0 5px;
    padding: 0 9px;
  `}
  & > img {
    margin-right: 4px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      display:none;
  `}
  }
`;

const Invitation = () => {
  const { account } = useWeb3React();
  const { t } = useTranslation();

  const toggleWalletModal = useWalletModalToggle();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const click = () => {
    if (account) {
      setShowConfirmation(true);
    } else {
      toggleWalletModal();
    }
  };
  return (
    <>
      <Btn onClick={click}>
        <img src={add_user_img} style={{ width: '20px' }} />
        {t('Invite')}
      </Btn>
      {account && (
        <InvitationModal
          isShow={showConfirmation}
          onDismiss={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};
export default Invitation;
