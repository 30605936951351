import React from 'react';
// import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import Logo from '@/assets/images/logo.png';
import { useDarkModeManager } from '@/state/user/hooks';
import Settings from '../Settings';
import LanguageMenu from './languageMenu';
import Menu from './Menu';
import Invitation from './invitation';
import Row, { RowBetween } from '../Row';
import Web3Status from '../Web3Status';
import { Btn } from './invitation';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import mapping_img from '@/assets/images/icon/mapping.png';

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  /* position: absolute; IP6*/
  z-index: 10;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: calc(100%);
    position: relative;
  `};
`;

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  /* :hover {
    cursor: pointer;
  } */
`;

const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  font-size: 22px;
  font-weight: 700;
  color: ${({ theme }) => theme.text1};
  margin-left: 10px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

const RowBetweenView = styled(RowBetween)`
  padding: 8px 1rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 8px .5rem;
  `};
`;

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
    cursor: pointer;
  }
  img {
    width: 2.5rem;
  }
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-end;
  `};
`;

const RowBetweenTheme = styled.div<{ selected: boolean }>`
  width: 100%;
  align-items: flex-start;
  border-bottom: 1px solid #eee;
  background: ${({ theme }) => theme.bg1};
`;

export default function Header() {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [isDark] = useDarkModeManager();

  return (
    <HeaderFrame>
      <RowBetweenTheme selected={true}>
        <RowBetweenView>
          <div style={{ display: 'flex' }}>
            <HeaderElement>
              <Title>
                <UniIcon>
                  <img src={isDark ? Logo : Logo} alt="logo" />
                </UniIcon>
                <TitleText>FlySwap</TitleText>
              </Title>
            </HeaderElement>
            <Menu />
          </div>

          <HeaderControls>
            <HeaderElement>
              <Invitation />
              <Btn onClick={() => push('/farms/mapping')}>
                <img src={mapping_img} style={{ width: '20px' }} />
                {t('mapping')}
              </Btn>

              {/* 语言 */}
              <LanguageMenu />
              {/* 设置 */}
              <Settings />

              {/* 账户 */}
              <Web3Status />
            </HeaderElement>
          </HeaderControls>
        </RowBetweenView>
      </RowBetweenTheme>
    </HeaderFrame>
  );
}
