export const END = process.env.REACT_APP_ENV;

let URL = 'https://service3.fly-swap.com';
if (END === 'development') {
  URL = 'http://192.168.1.37:28081'; //'http://154.85.62.206:12345';
} else if (END === 'test') {
  URL = 'https://service3.fly-swap.com';
}

type dataType = {
  [key: string]: {
    url: string;
    method: 'POST' | 'GET';
  };
};
const data = {
  // 通过地址获取信息
  getAddress: (address: string) => {
    return {
      url: `${URL}/api/v1/address/${address}`,
      method: 'GET',
    };
  },

  // 检测是否填过用户调查
  addAddress: {
    url: `${URL}/api/v1/address`,
    method: 'POST',
  },

  // 通过邀请地址获取信息列表
  parent_address: (address: string) => {
    return {
      url: `${URL}/api/v1/address/invitation/parent_address/${address}`,
      method: 'POST',
    };
  },

  // 提取邀请fly奖励
  extraction_record: (type: 'fly_extraction' | 'fibo_extraction') => {
    return {
      url: `${URL}/api/v1/extraction_record/${type}`,
      method: 'POST',
    };
  },
};

export default data;
