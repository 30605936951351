import React from 'react';
import { useActiveWeb3React } from '@/hooks';
import { useWalletModalToggle } from '@/state/application/hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Btn,
  ButtonPrimaryView,
} from '@/newPages/farms/list/component/farmsBtn';
import { usePoolFactory } from '@/newPages/Pool/hooks';
import { useParams } from 'react-router-dom';

interface IndexType {
  isVisible?: boolean;
  onClose?: () => any;
  data: any;
  isShowOpen?: boolean;
  height?: string;
  isShowOpenBtn?: boolean;
  disabled?: boolean;
  onClick?: () => any;
  pledgeSkipBtnDisabled?: boolean; // 质押跳转按钮禁用
  isInsufficientCreditLimit?: boolean;
}

const Index = (props: IndexType) => {
  const {
    data,
    height = '',
    isShowOpenBtn = false,
    disabled = false,
    pledgeSkipBtnDisabled = false,
    onClick,
    isInsufficientCreditLimit = false, // 额度不足
  } = props;
  const { TokenName }: any = useParams();
  const { account } = useActiveWeb3React();
  const toggleWalletModal = useWalletModalToggle(); // toggle wallet when disconnected
  const res: any = usePoolFactory();

  const { t } = useTranslation();
  const { push } = useHistory();

  if (!account) {
    return (
      <ButtonPrimaryView onClick={toggleWalletModal} height={height}>
        {t('Connect Wallet')}
      </ButtonPrimaryView>
    );
  }
  const disableds = res?.loading || disabled;

  if (
    !res?.stakingTokenIsNative &&
    ((data && res && !res?.isOpen && isShowOpenBtn) ||
      isInsufficientCreditLimit)
  ) {
    return (
      <ButtonPrimaryView
        height={height}
        onClick={res?.openPool}
        disabled={res?.loading}
      >
        {isInsufficientCreditLimit
          ? t('Insufficient authorized amount Re-authorize')
          : t('enable')}
      </ButtonPrimaryView>
    );
  }

  return (
    <Btn
      height={height}
      disabled={isShowOpenBtn ? disableds : pledgeSkipBtnDisabled}
      onClick={() => {
        if (isShowOpenBtn) {
          onClick?.();
          // 质押
        } else {
          push(`/pool/pledge/${TokenName}/${data?.pid}`);
        }
      }}
    >
      {t('stake')}
    </Btn>
  );
};
export default Index;
