import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { poolList } from '@/newPages/1230';
import Header from '@/newPages/farms/pledge/module/header';
import { poolFactoryType } from '@/constants';
import { useTranslation } from 'react-i18next';
import { Slider, Divider, Radio, message } from 'antd';
import BalanceView from '@/newPages/farms/component/Balance';
import { Input as NumericalInput } from '@/components/NumericalInput';
import { useActiveWeb3React } from '@/hooks';
import { StyledBalanceMax } from '@/components/CurrencyInputPanel';
import PoolBtn from '../component/PoolBtn';
import { useGetPoolPledgeType } from './hooks';
import { usePoolFactory, useGetpoolInfo } from '../hooks';
import { poolPledgeDit, poolPledgeDitTooltip } from './hooks';
import { digitalPrecision } from '@/new_hooks/utils';
import dayjs from 'dayjs';
import QuestionHelper from '@/components/QuestionHelper';
import { BigNumber } from 'bignumber.js';
import styled from 'styled-components';
import { useTvl } from '../hooks';
import Loader from '@/components/Loader';
import { PoolView } from '@/newPages/farms/pledge';

const RadioButton = styled(Radio.Button)`
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    padding:0 10px;
  `};
`;

export const NumericalInputBox = styled(NumericalInput)`
  padding: 0;
  margin-right: 8px;
  border: 1px solid ${({ theme }) => theme.primary1};
  border-radius: 10px;
  padding: 12px 16px;
  width: 100%;
  font-size: 20px;
  color: ${({ theme }) => theme.primary1};
`;

const InputWrap = styled.div`
  position: relative;
`;

const StyledBalanceMaxBtn = styled(StyledBalanceMax)`
  color: ${({ theme }) => theme.primary1};
  background-color: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
  margin: 0;
`;

const PledgeView = styled.div`
  /* padding: 26px; */
  & .title {
    font-size: 14px;
    font-weight: bold;
  }
  .radio_wrap {
    margin: 22px 0 24px;
  }
`;

const HeaderBalance = styled.div`
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  & .title {
    font-size: 20px;
    font-weight: bold;
  }
  & .symbol {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
  }
`;

const RadioView = styled(Radio.Group)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .ant-radio-button-wrapper {
    border-radius: 14px !important;
    background-color: ${({ theme }) => theme.primary5};
    &::before {
      display: none;
    }
    border: 1px solid #d9d9d9;
  }
`;

const DividerView = styled(Divider)`
  margin: 50px 0 0;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-top: 18px;
`;

const Details = styled.div`
  /* width: 355px; */
  width: 100%;
  height: 123px;
  border-radius: 10px;
  opacity: 1;
  border: 1px solid #9ec1bd;
  margin-bottom: 24px;
  padding: 16px;
  font-weight: bold;
`;

const marks = {
  0: '0%',
  25: '25%',
  50: '50%',
  75: '75%',
  100: {
    style: {
      color: '#f50',
    },
    label: <strong>100%</strong>,
  },
};

const Pool = () => {
  const params: any = useParams();
  const { pid } = params || {};
  const TokenName: poolFactoryType = params?.TokenName;
  const { t } = useTranslation();

  const getLp: any = () =>
    poolList[TokenName]?.find((item: any) => item.pid === Number(pid));

  const { value }: any = useGetPoolPledgeType(pid);
  const res = usePoolFactory();

  const {
    list: poolPledgeTypeData,
    stakingSymbol,
    stakingTokenBalances,
    decimals,
    stakingTokenIsNative,
  } = value;

  const { account } = useActiveWeb3React();
  const [amount, setaAmount] = useState<any>('0'); // 金额
  const [poolPledgeData, setPoolPledgeData] = useState<any>(); // 存款对象
  const { apr } = useTvl(pid);
  const { endTime } = useGetpoolInfo(pid) ?? {};

  const { loading = false, allowance }: any = res ?? {};

  const clickPledge = () => {
    if (Number(amount) > Number(stakingTokenBalances)) {
      message.error('余额不足');
      return;
    }
    if (poolPledgeData === undefined) {
      return;
    }

    const new_amount = digitalPrecision(amount, decimals);
    res?.deposit(
      getLp().pid,
      poolPledgeData.value,
      new_amount,
      () => {
        setaAmount('0');
        setPoolPledgeData(null);
      },
      stakingTokenIsNative ? amount : '',
    );
  };

  const disabled: boolean =
    amount <= 0 || !((poolPledgeData ?? '') !== '') || loading;

  return (
    <>
      <PoolView>
        <Header
          title={t('Pledge to get rewards', {
            token1: TokenName.toLocaleUpperCase(),
            token2: stakingSymbol,
          })}
        />
        <PledgeView>
          <HeaderBalance>
            <div className="title">{stakingSymbol}</div>
            <span>
              {t('balance')}
              {/* <TokenNum address={stakingToken} /> */}
              <BalanceView balance={stakingTokenBalances} />
            </span>
          </HeaderBalance>
          <InputWrap>
            <NumericalInputBox
              value={amount}
              onUserInput={setaAmount}
              disabled={loading}
              max={stakingTokenBalances}
            />
            <>
              {account && Number(amount) !== Number(stakingTokenBalances) && (
                <StyledBalanceMaxBtn
                  onClick={() => setaAmount(stakingTokenBalances)}
                >
                  MAX
                </StyledBalanceMaxBtn>
              )}
            </>
          </InputWrap>
          <Slider
            marks={marks}
            value={(amount / stakingTokenBalances) * 100}
            onChange={(v: any) => {
              setaAmount((v / 100) * stakingTokenBalances);
            }}
            disabled={!stakingTokenBalances || loading}
          />
          <DividerView />
          {poolPledgeTypeData && (
            <div>
              <Title>{t('lock time')}</Title>
              <RadioView
                buttonStyle="solid"
                onChange={e => {
                  setPoolPledgeData(
                    poolPledgeTypeData.find(
                      (item: any) => item.value === e.target.value,
                    ),
                  );
                }}
                value={poolPledgeData?.value}
                disabled={loading}
              >
                {poolPledgeTypeData &&
                  poolPledgeTypeData?.length > 0 &&
                  poolPledgeTypeData.map((item: any) => {
                    return (
                      <RadioButton value={item.value} key={item.value}>
                        {item.label}
                      </RadioButton>
                    );
                  })}
              </RadioView>
            </div>
          )}
          <div>
            <Title>{t('Lockdown overview')}</Title>
            <Details>
              <div className="flex-between">
                <span>APR</span>
                <span>{apr ? apr : <Loader />}</span>
              </div>
              <div className="flex-between">
                <span>{t('lock time')}</span>
                <span>{poolPledgeDit(t)[poolPledgeData?.value]}</span>
              </div>
              <div className="flex-between">
                <span>{t('Revenue boost')}</span>
                <span>
                  {poolPledgeData?.multiplier && (
                    <div className="flex-item-center">
                      {poolPledgeData?.multiplier / 1e18}X
                      <QuestionHelper
                        text={t(poolPledgeDitTooltip[poolPledgeData?.value])}
                      />
                    </div>
                  )}
                </span>
              </div>
              <div className="flex-between">
                <span>
                  {poolPledgeData?.value === 0
                    ? t('End the block')
                    : t('unlock date')}
                </span>
                <span>
                  {poolPledgeData?.duration && (
                    <>
                      {poolPledgeData?.value === 0
                        ? `${endTime} ${t('Block')}`
                        : dayjs(
                            (dayjs().unix() +
                              Number(poolPledgeData?.duration)) *
                              1000,
                          ).format('YYYY-MM-DD HH:mm:ss')}
                    </>
                  )}
                </span>
              </div>
            </Details>
          </div>
          <PoolBtn
            data={getLp()}
            isShowOpenBtn
            disabled={disabled}
            onClick={clickPledge}
            isInsufficientCreditLimit={
              Number(amount) > Number(BigNumber(allowance).div(10 ** decimals))
            }
          />
        </PledgeView>
      </PoolView>
    </>
  );
};
export default Pool;
