import { useMemo } from 'react';
import { useSingleResult } from '../../hooks';
import { usePoolFactoryContract } from '@/hooks/useContract';
import { useCurrencyBalances } from '@/new_hooks/Tokens';
import { toReturnState } from '@/new_hooks/utils';
import { useTranslation } from 'react-i18next';
import { useGetTokenType } from '../hooks';
import { ETHER } from 'flyswap-skdv1';
import { useETHBalances } from '@/state/wallet/hooks';
import { useActiveWeb3React } from '@/hooks';
import { useCurrency } from '@/hooks/Tokens';
import { useToken } from '@/new_hooks/Tokens';

export const poolPledgeDit: any = (t: any) => {
  return {
    0: t('cycle lock'),
    1: 'T + 1',
    2: t('14 days'),
    3: t('n month', { num: 1 }),
    4: t('n month', { num: 3 }),
  };
};

export const poolPledgeDitTooltip: any = {
  0: 'cycle lock Tooltip',
  1: 'In unit time, it takes 24 hours to withdraw the principal',
  2: 'The principal needs to be pledged for 14 days, which has nothing to do with the end time of the event, and after the end time of the event, there will be no rewards',
  3: 'The principal needs to be pledged for 1 month, which has nothing to do with the end time of the event, and after the end time of the event, there will be no rewards',
  4: 'The principal needs to be pledged for 3 month, which has nothing to do with the end time of the event, and after the end time of the event, there will be no rewards',
};

export const useGetPoolPledgeType = (pid?: number) => {
  const { account } = useActiveWeb3React();

  const tokenNameType = useGetTokenType();
  // balanceOf
  const poolFactoryContract: any = usePoolFactoryContract(tokenNameType);
  const getAllBillType = useSingleResult(poolFactoryContract, 'getAllBillType');
  const stakingToken = useSingleResult(poolFactoryContract, 'stakingToken'); // 质押 ToToken 地址

  const stakingTokenIsNative = useSingleResult(
    poolFactoryContract,
    'stakingTokenIsNative',
  ); // ToToken 是否为稳定币(只有 FIBO 链的 wfibo 为 true

  const { t } = useTranslation();
  const {
    value: { balances },
  } = useCurrencyBalances(stakingToken ?? '');

  const poolInfo = useSingleResult(poolFactoryContract, 'poolInfo', [pid]);
  const {
    value: { symbol: stakingSymbol },
  } = useToken(poolInfo?.rewardToken);

  const { decimals } =
    useCurrency(stakingTokenIsNative ? ETHER.symbol : stakingToken) ?? {};

  const userEthBalance = useETHBalances(account ? [account] : [])?.[
    account ?? ''
  ];
  const stakingTokenBalances = stakingTokenIsNative
    ? userEthBalance?.toSignificant()
    : balances;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setpledgeType = (data: any[]): any[] => {
    if (!data?.length) return [];
    const typesArr = [];
    for (let index = 0; index < data.length; index++) {
      const element: any = data[index];
      const label = poolPledgeDit(t)[element.id.toString()];
      typesArr.push({
        ...element,
        // id: element.id.toString(),
        value: index,
        label: label,
        duration: element.duration.toString(),
        multiplier: element.multiplier.toString(),
      });
    }
    return typesArr;
  };

  return useMemo(() => {
    // if (!getAllBillType) return null;
    const obj = {
      list: setpledgeType(getAllBillType),
      stakingSymbol,
      stakingToken,
      stakingTokenBalances,
      decimals,
      stakingTokenIsNative,
    };
    return toReturnState(obj);
  }, [getAllBillType, setpledgeType, stakingTokenIsNative]);
};
