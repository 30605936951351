import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { ExternalLink as LinkIcon } from 'react-feather';
import {
  Link,
  Item,
  Title,
  HeaderContent,
  AccordionContent,
  AccordionContentBox,
  AccordionContentBoxTitle,
  DetailsBtn,
  RowRprView,
  HeaderAPR,
  LpTokenName,
} from '@/newPages/farms/list/component/AccordionList/styles';
import { useTranslation } from 'react-i18next';
import Loader from '@/components/Loader';
import styled from 'styled-components';
import { ButtonPrimary } from '@/components/Button';
import PoolBtn from '../PoolBtn';
import fibo_img from '@/assets/images/icon/fibo_icon.png';
import { useToken } from '@/new_hooks/Tokens';
import block_img from '@/assets/images/icon/block.png';
import TokenNum from '@/newPages/farms/component/TokenNum';
import Balance from '@/newPages/farms/component/Balance';

import { useTvl } from '../../hooks';
import { getEtherscanLink } from '@/utils';
import { useActiveWeb3React } from '@/hooks';
import { NETWORK_CHAIN_ID } from '@/connectors';
import { useGetTokenType } from '../../hooks';
import { usePoolFactoryContract } from '@/hooks/useContract';
import { DEFAULT_INPUT_CURRENCY } from '@/connectors';
import { FUSDToken } from '@/newPages/1230';

// ***************************** s b 一样的东西 非要样式不一样
const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

const QuoteTokenView = styled.div`
  position: absolute;
  bottom: auto;
  left: 1px;
  top: 1px;
  z-index: 5;

  max-height: 35px;
  max-width: 35px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
 max-height: 28px;
  max-width: 28px;
  `};
`;
const TokenView = styled.div`
  position: absolute;
  bottom: -2px;
  right: -2px;
  top: auto;
  z-index: 6;
  max-height: 24px;
  max-width: 24px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
 max-height: 20px;
  max-width: 20px
  `};
`;
const LplogoView = styled.div`
  width: 40px;
  height: 40px;
  flex: none !important;
  position: relative;
  margin-right: 6px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 30px;
  height: 30px;
  `};
`;

export const Lplogo = (props: AccordionListType) => {
  const { data } = props;

  const setUrl = (token: any) => {
    let adderss = token?.address;
    if (token?.symbol === 'FUSD') {
      adderss = FUSDToken;
    }
    const symbol =
      token?.symbol === DEFAULT_INPUT_CURRENCY
        ? token?.symbol
        : `${token?.symbol}_${adderss}`;

    return `https://chainfb.oss-cn-hangzhou.aliyuncs.com/ETH/12306/${symbol}.png`;
  };
  const url = [setUrl(data.quoteToken), setUrl(data.token)];

  return (
    <LplogoView>
      <QuoteTokenView>
        <Logo src={url[0]} alt="" />
      </QuoteTokenView>
      <TokenView>
        <Logo src={url[1]} alt="" />
      </TokenView>
    </LplogoView>
  );
};
// *****************************

const AccordionContentBoxs = styled(AccordionContentBox)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 10px!important;
  `};
`;
export const PledgeBtn = styled(ButtonPrimary)`
  height: 36px;
  border-radius: 18px;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  white-space: nowrap;
`;

export const HeaderContents = styled(HeaderContent)`
  font-weight: bold;
  & > div {
    ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: start;
  `};
  }
`;

export const Symbol = styled.div`
  font-size: 20px;
  margin-bottom: 5px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    margin-bottom: 0px;
  `};
`;
export const SymbolTitle = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`;

interface AccordionListType {
  data: any;
}

const AccordionList = (props: AccordionListType) => {
  const { data } = props;
  const { chainId } = useActiveWeb3React();
  const { poolInfo, endTime, userReward } = data;
  const {
    value: { symbol, address },
  } = useToken(poolInfo?.rewardToken);

  const { t } = useTranslation();
  const [Stow, setStow] = useState<boolean>(false);
  const TokenName = useGetTokenType();
  const PoolFactoryContract = usePoolFactoryContract(TokenName);
  const { apr, tvlNum } = useTvl(data.pid);
  const Apr = () => <>{apr ? apr : <Loader />}</>;

  const Tvl = () => (
    <SymbolTitle>
      <Balance balance={Number(tvlNum) ?? '0'} />$
    </SymbolTitle>
  );

  return (
    <Item>
      <HeaderContents>
        <div>
          <div className="flex">
            <LpTokenName>
              <Lplogo data={data} />
            </LpTokenName>

            <div>
              <Symbol>
                {t('To Earn')}&nbsp;
                {symbol}
              </Symbol>
              <SymbolTitle style={{ color: '#80BCB4' }}>
                {t('pledge')}
                {TokenName.toLocaleUpperCase()}
              </SymbolTitle>
            </div>
          </div>
        </div>

        <HeaderAPR>
          APR：
          <SymbolTitle>
            <Apr />
          </SymbolTitle>
        </HeaderAPR>

        <div>
          <Title>
            {t('Earned')}
            {symbol}
          </Title>
          <SymbolTitle>
            <TokenNum num={userReward} address={address} />
          </SymbolTitle>
        </div>

        <RowRprView>
          <Title>APR</Title>
          <Apr />
        </RowRprView>

        <div>
          <Title>TVL</Title>
          <Tvl />
        </div>

        <div>
          <Title>{t('End Countdown')}</Title>
          <SymbolTitle>
            <img src={block_img} style={{ width: '18px' }} />
            {endTime > 0 ? endTime + ` ${t('Block')}` : t('The event is over')}
          </SymbolTitle>
        </div>

        <DetailsBtn onClick={() => setStow(!Stow)} className="cursor">
          {!Stow ? <ChevronDown /> : <ChevronUp />}
        </DetailsBtn>
      </HeaderContents>
      {/*  */}
      <AccordionContent Stow={Stow}>
        <div>
          <Link
            href={getEtherscanLink(
              chainId ?? NETWORK_CHAIN_ID,
              poolInfo?.rewardToken,
              'address',
            )}
          >
            {t('View token information')}
            <LinkIcon size={16} />
          </Link>

          <Link
            href={getEtherscanLink(
              chainId ?? NETWORK_CHAIN_ID,
              PoolFactoryContract?.address ?? '',
              'address',
            )}
          >
            {t('View contract')}&nbsp;
            <img src={fibo_img} style={{ width: '14px' }} />
          </Link>
        </div>
        <AccordionContentBoxs>
          <AccordionContentBoxTitle>
            {t('Start Farming')}
          </AccordionContentBoxTitle>
          <PoolBtn
            data={data}
            height="36px"
            pledgeSkipBtnDisabled={endTime < 0}
          />
        </AccordionContentBoxs>
      </AccordionContent>
    </Item>
  );
};

export default AccordionList;
