import styled from 'styled-components';
import { ReactComponent as Close } from '@/assets/images/x.svg';
import { Input } from '@/components/AddressInputPanel';
import { ButtonPrimary } from '@/components/Button';

const CloseColor = styled(Close)`
  cursor: pointer;
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`;
const Main = styled.div`
  padding: 26px 16px;
  color: ${({ theme }) => theme.primary1};
`;
const Header = styled.div`
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  background: ${({ theme }) =>
    theme.darkMode
      ? theme.bg1
      : ` linear-gradient(126deg, #f1ecf2 0%, #e8f1f6 100%)`};
  padding: 0 30px 0 21px;
  font-size: 22px;
  font-weight: 800;
  color: #349085;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;
const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  font-weight: bold;
  color: ${props =>
    props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit'};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`;
const ContentData = styled.div`
  background-color: ${({ theme }) => theme.primary5};
  border-radius: 16px;
  padding: 32px 20px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 10px 8px;
  `};
`;
const FooterBox = styled(ContentData)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  margin-top: 27px;
  font-size: 24px;
  font-weight: 800;
  ${({ theme }) => theme.mediaWidth.upToSmall`
   font-size: 16px;
   .select{
    font-size:14px;
   }
   .select_icon{
    width: 20px;
    height:20px;
    margin-right:5px;
   }
  `}
`;
const DataItem = styled.div`
  margin-top: 57px;
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-wrap: wrap;
    margin-top: 14px;
  `};
`;
const InvitationInput = styled(Input)`
  border-color: transparent;
  height: 59px;
  background: #e9f5f3;
  width: 100%;
  padding: 0 15px;
`;
const DataItemRow = styled.div`
  text-align: center;
  padding: 0 10px;
  font-size: 20px;
  white-space: nowrap;
  font-weight: 800;
  margin-top: 10px;
  .select {
    margin: 0;
  }
  &:last-child {
    flex: 1;
  }
  &:not(:last-child) {
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background-color: ${({ theme }) => theme.primary1};
      opacity: 0.2;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    flex: none;
    width: 100%;
    &:not(:last-child) {
      &::after {
        opacity: 0;
      }
    }
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 15px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 12px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
      width:100%;
      display:flex;
      justify-content: space-between;
      align-item:center;
      &:not(:last-child) {
        &::after {
         display:none;
        }
      }
      & > div:last-child {
        width: 60%;
        display:flex;
        justify-content: start;
        .gRNQTd{
          margin-left:0;
        }
      }
  `};
`;

const DataItemRowtTitle = styled.div`
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 0;
  `};
`;

const CopyCodeBox = styled.div`
  & > div {
    background-color: transparent;
    border: 1px solid #22b1cd;
  }
  & input {
    font-size: 20px;
  }
  width: 290px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    & input {
      font-size: 14px;
    }
  `}
`;
const ExtractButton = styled(ButtonPrimary)`
  width: 293px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 93px;
    height: 31px;
  `};
`;

const Record = styled(FooterBox)`
  box-sizing: border-box;
  /* max-height: 216px; */
  display: flex;
  flex-direction: column;
  & .title {
    color: #349085;
    margin-bottom: 10px;
    width: 100%;
    font-size: 18px;
    font-weight: 800;
  }
  .header {
    font-size: 16px;
    font-weight: 800;
    color: #80bcb4;
    margin: 10px 0;
    width: 100%;
  }
  .item {
    font-size: 18px;
    font-weight: 800;
    margin: 12px 0;
  }
  .content_list {
    width: 100%;
    overflow: auto;
    flex: 1;
    padding-right: 10px;
    text-align: center;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    .title{
      font-size: 14px;
    }
    .item, .header{
      font-size: 12px;
    }
  `}
`;
export {
  CloseColor,
  Main,
  Header,
  HeaderRow,
  ContentData,
  FooterBox,
  DataItem,
  InvitationInput,
  DataItemRow,
  CopyCodeBox,
  ExtractButton,
  Record,
  DataItemRowtTitle,
};
