import { Currency } from 'flyswap-skdv1';
import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import useLast from '@/hooks/useLast';
import { useSelectedListUrl } from '@/state/lists/hooks';
import Modal from '../Modal';
import { CurrencySearch } from './CurrencySearch';
import ListIntroduction from './ListIntroduction';
import { ListSelect } from './ListSelect';
import { NETWORK_CHAIN_ID } from '@/connectors';
import { useActiveWeb3React } from '@/hooks';

interface CurrencySearchModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  selectedCurrency?: Currency | null;
  onCurrencySelect: (currency: Currency) => void;
  otherSelectedCurrency?: Currency | null;
  showCommonBases?: boolean;
}

export default function CurrencySearchModal({
  isOpen,
  onDismiss,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
  showCommonBases = false,
}: CurrencySearchModalProps) {
  const [listView, setListView] = useState<boolean>(false);
  const lastOpen = useLast(isOpen);
  const { chainId } = useActiveWeb3React();

  useEffect(() => {
    if (isOpen && !lastOpen) {
      setListView(false);
    }
  }, [isOpen, lastOpen]);

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency);
      onDismiss();
    },
    [onDismiss, onCurrencySelect],
  );

  const handleClickChangeList = useCallback(() => {
    ReactGA.event({
      category: 'Lists',
      action: 'Change Lists',
    });
    setListView(true);
  }, []);
  const handleClickBack = useCallback(() => {
    ReactGA.event({
      category: 'Lists',
      action: 'Back',
    });
    setListView(false);
  }, []);
  const handleSelectListIntroduction = useCallback(() => {
    setListView(true);
  }, []);

  const selectedListUrl = useSelectedListUrl();
  const noListSelected = !selectedListUrl;
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      maxHeight={90}
      minHeight={
        listView
          ? 40
          : noListSelected
          ? 0
          : chainId === NETWORK_CHAIN_ID
          ? 0
          : 80
      }
    >
      {listView && chainId !== NETWORK_CHAIN_ID ? (
        <ListSelect onDismiss={onDismiss} onBack={handleClickBack} />
      ) : noListSelected && chainId !== NETWORK_CHAIN_ID ? (
        <ListIntroduction onSelectList={handleSelectListIntroduction} />
      ) : (
        <CurrencySearch
          isOpen={isOpen}
          onDismiss={onDismiss}
          onCurrencySelect={handleCurrencySelect}
          onChangeList={handleClickChangeList}
          selectedCurrency={selectedCurrency}
          otherSelectedCurrency={otherSelectedCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </Modal>
  );
}
