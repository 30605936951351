import { useMemo } from 'react';
import { useActiveWeb3React } from '@/hooks';
import { useSingleResult } from '@/newPages/hooks';
import { useMsterChefContract } from '@/hooks/useContract';

export const useGetBillList = () => {
  const { account, chainId } = useActiveWeb3React();
  const masterChef = useMsterChefContract();
  const poolLength = useSingleResult(masterChef, 'getUserHistoryBill', [
    account,
    1e10,
    10000,
  ]);

  const setData = (data = []) => {
    return data
      .map((item: any) => ({
        ...item,
        billId: item.billId.toString(),
        timestamp: item.timestamp.toString(),
        singleAmount: item.singleAmount.toString(),
        lockStartTime: item.lockStartTime.toString(),
      }))
      .filter(item => item.singleAmount && item.isDeposit);
  };
  return useMemo(() => {
    if (!chainId || !account) return undefined;
    if (!poolLength) {
      return {
        list: [],
        loading: true,
      };
    }

    return {
      list: setData(poolLength),
      loading: !!!poolLength,
    };
  }, [account, poolLength]);
};
