import { Contract } from '@ethersproject/contracts';
import { ChainId, WETH } from 'flyswap-skdv1';
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json';
import { useMemo } from 'react';
import ENS_ABI from '@/constants/abis/ens-registrar.json';
import ENS_PUBLIC_RESOLVER_ABI from '@/constants/abis/ens-public-resolver.json';
import { ERC20_BYTES32_ABI } from '@/constants/abis/erc20';
import ERC20_ABI from '@/constants/abis/erc20.json';
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '@/constants/abis/migrator';
import UNISOCKS_ABI from '@/constants/abis/unisocks.json';
import WETH_ABI from '@/constants/abis/weth.json';
import MASTERCHEF from '@/constants/abis/masterChef.json';
import TOKENMAPPING from '@/constants/abis/TokenMapping.json';
import POOLFACTORY from '@/constants/abis/PoolFactory.json';

import { MULTICALL_ABI, MULTICALL_NETWORKS } from '@/constants/multicall';
import {
  V1_EXCHANGE_ABI,
  V1_FACTORY_ABI,
  V1_FACTORY_ADDRESSES,
} from '@/constants/v1';
import { getContract } from '@/utils';
import { useActiveWeb3React } from './index';
import {
  masterChef,
  mappingToken,
  poolFactory,
  poolFactoryType,
} from '@/constants';

// TODO: 合约
function useContract(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): Contract | null {
  const { library, account, chainId } = useActiveWeb3React();

  return useMemo(() => {
    // TODO 拦截 支持链拦截
    // if (chainId && !WETH[chainId]) {
    //   console.log('目前 仅支持 FIBO');
    //   return null;
    // }
    if (!address || !ABI || !library) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined,
      );
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}

// erc20合约
export const useErcContract = () => {
  const { library, account } = useActiveWeb3React();
  return (address: string) => {
    if (!address || !library || !account) return null;
    return getContract(address, ERC20_ABI, library, account);
  };
};

export function useMsterChefContract(): Contract | null {
  return useContract(masterChef, MASTERCHEF);
}
export function useMappingContract(): Contract | null {
  return useContract(mappingToken, TOKENMAPPING, true);
}

// pool合约
export function usePoolFactoryContract(
  nameType: poolFactoryType,
): Contract | null {
  return useContract(poolFactory[nameType], POOLFACTORY, true);
}

export function useV1FactoryContract(): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(
    chainId && V1_FACTORY_ADDRESSES[chainId],
    V1_FACTORY_ABI,
    false,
  );
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true);
}

export function useV1ExchangeContract(
  address?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(address, V1_EXCHANGE_ABI, withSignerIfPossible);
}

// TODO: erc20用户合约（用户合约可以查询token地址的信息等。）
export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useWETHContract(
  withSignerIfPossible?: boolean,
): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(
    chainId && WETH[chainId] ? WETH[chainId].address : undefined,
    WETH_ABI,
    withSignerIfPossible,
  );
}

export function useENSRegistrarContract(
  withSignerIfPossible?: boolean,
): Contract | null {
  const { chainId } = useActiveWeb3React();
  let address: string | undefined;
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.GÖRLI:
      case ChainId.ROPSTEN:
      case ChainId.RINKEBY:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e';
        break;
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible);
}

export function useENSResolverContract(
  address: string | undefined,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible);
}

export function useBytes32TokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible);
}

export function usePairContract(
  pairAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible);
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(
    chainId && MULTICALL_NETWORKS[chainId],
    MULTICALL_ABI,
    false,
  );
}

export function useSocksController(): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(
    chainId === ChainId.MAINNET
      ? '0x65770b5283117639760beA3F867b69b3697a91dd'
      : undefined,
    UNISOCKS_ABI,
    false,
  );
}
