import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import { useGetBillList } from './hooks';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { ModalViewBox } from '@/newPages/farms/mapping';
import HistoricalBillListItem from './item';
import HeaderTab from '../component/headerTab';
import { FarmsListAppBody } from '@/newPages/farms/list';
import { List } from 'antd';

export const SelevtItem = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 600;
  .select-area {
    width: 186px;
    .css-13cymwt-control {
      border-radius: 8px;
      border-color: ${({ theme }) => theme.primary1};
      background-color: ${({ theme }) => theme.bg1};
      & div {
        color: ${({ theme }) => theme.primary1};
      }
    }
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 14px;
    .select-area {
      width: 95px;
      .css-13cymwt-control {
        height: 26px;
      }
    }
  `};
`;

export const options = (t: any) => [
  {
    value: '',
    label: t('all'),
  },
  {
    value: true,
    label: t('pledge'),
  },
  {
    value: false,
    label: t('redemption'),
  },
];

export const HistoricalBillView = styled.div`
  background-color: transparent;
  .header {
    background: transparent;
    padding: 0;
    font-size: 18px !important;
  }
  .content {
    border-radius: 16px;
    padding-bottom: 24px;
  }
  .card {
    ${({ theme }) => theme.mediaWidth.upToLarge`
      width:96vw;
  `};
  }

  .ant-list-bordered {
    border-radius: 16px;
    overflow: hidden;
  }
  .ant-pagination {
    display: flex;
    justify-content: center;
  }
`;

const HistoricalBill = () => {
  const { list, loading }: any = useGetBillList();
  const [billType, setBillType] = useState<any>('');
  const { t } = useTranslation();

  const filter = (data: any) => {
    if ((billType ?? '') !== '') {
      return data.filter((item: any) => {
        if (billType === true && item.isDeposit) {
          return item;
        } else if (
          billType === false &&
          !item.isDeposit &&
          !BigNumber(item.singleAmount).isZero()
        ) {
          return item;
        }
      });
    }
    return data;
  };

  const optionsData = options(t);
  return (
    <>
      <HeaderTab />

      <HistoricalBillView>
        <ModalViewBox
          width="1018px"
          overflow="visible"
          marginTop="50px"
          select={
            <>
              {optionsData && (
                <SelevtItem>
                  {t('Types')}&nbsp;
                  <Select
                    isSearchable={false}
                    className="select-area"
                    options={optionsData}
                    defaultValue={optionsData[0]}
                    placeholder="* 选择质押类型"
                    value={optionsData.find(
                      (item: any) => item.value === billType,
                    )}
                    onChange={(t: any) => {
                      setBillType(t.value);
                    }}
                  />
                </SelevtItem>
              )}
            </>
          }
        >
          <List
            dataSource={filter(list)}
            renderItem={(item: any) => (
              <HistoricalBillListItem data={item} key={item?.billId} />
            )}
            loading={loading}
            pagination={{
              pageSize: 10,
            }}
          />
        </ModalViewBox>
      </HistoricalBillView>
    </>
  );
};
export default HistoricalBill;
