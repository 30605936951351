import React from 'react';
import Modal from '@/new_components/Modal';
import { Button } from 'antd';
import { Collapse } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useGetbillInfo } from '../hooks';
import { useGetpoolInfo } from '../../hooks';
import { useToken } from '@/new_hooks/Tokens';
import TokenNum from '@/newPages/farms/component/TokenNum';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

const CollapseView = styled(Collapse)`
  .ant-collapse-header {
    margin: auto;
    width: 80px;
    white-space: nowrap;
    color: ${({ theme }) => theme.primary1}!important;
    font-weight: bold;
    width: fit-content;
  }
  .ant-collapse-item {
    background-color: #fff;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
`;

const RowBetween = styled.div`
  color: ${({ theme }) => theme.primary1};
  display: flex;
  justify-content: space-between;
`;

const FontBold = styled.div`
  font-weight: bold;
`;

interface IndexType {
  isVisible?: boolean;
  onClose?: () => any;
  onClick?: () => any;
  orderData?: any | null;
  loading?: boolean;
}

const Index: React.FC<IndexType> = (props: IndexType) => {
  const { isVisible, onClose, onClick, orderData, loading } = props;
  const { billInfo, pending } = useGetbillInfo(
    orderData.pid?.toString(),
    orderData.bid?.toString() ?? null,
  );

  const poolData = useGetpoolInfo(orderData.pid);
  const { t } = useTranslation();

  const {
    value: { symbol, address },
  } = useToken(poolData?.poolInfo?.rewardToken ?? undefined);

  const customPanelStyle = {
    borderRadius: 4,
    border: 0,
    overflow: 'hidden',
    paddingBottom: '10px',
  };

  return (
    <>
      <Modal
        visible={isVisible}
        title={t('Withdraw proceeds')}
        width="411px"
        onCancel={onClose}
        footer={
          <CollapseView
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              !isActive ? <ChevronDown size={18} /> : <ChevronUp size={18} />
            }
          >
            <Panel header={`${t('Details')} `} key="1" style={customPanelStyle}>
              <div>
                <RowBetween>
                  <div>{t('Pledge time')}</div>
                  <FontBold>
                    {dayjs(billInfo?.lockStartTime * 1000).format(
                      'YYYY-MM-DD HH:mm',
                    )}
                  </FontBold>
                </RowBetween>
                <RowBetween>
                  <div>{t('Lockup end time')}</div>
                  <FontBold>
                    {dayjs(billInfo?.lockEndTime * 1000).format(
                      'YYYY-MM-DD HH:mm',
                    )}
                  </FontBold>
                </RowBetween>
                <RowBetween>
                  <div>{t('Event end time')}</div>
                  <FontBold>
                    {poolData && Number(poolData?.endTime) > 0
                      ? poolData?.endTime + ` ${t('Block')}`
                      : t('The event is over')}
                  </FontBold>
                </RowBetween>
              </div>
            </Panel>
          </CollapseView>
        }
      >
        <div>
          <p>
            <RowBetween>
              <span>{t('Accumulative earned income')}</span>
              <FontBold>
                <TokenNum
                  num={billInfo?.historyRewardValue.toString()}
                  address={address}
                />
                {symbol}
              </FontBold>
            </RowBetween>
          </p>
          <p>
            <RowBetween>
              <span>{t('Withdrawable income')}</span>
              <FontBold>
                <TokenNum num={pending ?? ''} address={address} />
                {symbol}
              </FontBold>
            </RowBetween>
          </p>

          <Button
            type="primary"
            block
            onClick={onClick}
            size="large"
            loading={loading}
            disabled={Number(pending) === 0}
          >
            {t('extract')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default Index;
