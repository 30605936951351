import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { ModalViewBox } from '@/newPages/farms/mapping';
import HistoricalBillListItem from './item';
import { PoolNavigation } from '../index';
import { poolFactoryType } from '@/constants';
import { useParams } from 'react-router-dom';
import { useGetBillList } from './hooks';
import { useGetPoolPledgeType } from '../pledge/hooks';
import { List } from 'antd';
import {
  SelevtItem,
  options,
  HistoricalBillView,
} from '@/newPages/farms/historicalBill';
import styled from 'styled-components';

const Title = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display:none;
  `}
`;
const SelectView = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width:100%;
    justify-content: space-between;
  `}
`;
const HistoricalBill = () => {
  const { TokenName }: { TokenName: poolFactoryType } = useParams();

  const [billType, setBillType] = useState<any>('');
  const [lockType, setLockType] = useState<any>('');

  const { t } = useTranslation();

  const { value } = useGetPoolPledgeType();

  const { list: selectList }: any = value ?? {};

  const filter = (data: any) => {
    let newData = data;
    if ((billType ?? '') !== '') {
      newData = newData.filter((item: any) => {
        if (billType === true && item.isDeposit) {
          return item;
        } else if (
          billType === false &&
          !item.isDeposit &&
          !BigNumber(item.singleAmount).isZero()
        ) {
          return item;
        }
      });
    }
    if ((lockType ?? '') !== '') {
      newData = newData.filter(
        (item: any) => Number(item.billType) === lockType,
      );
    }
    return newData;
  };

  const { list, loading }: any = useGetBillList(TokenName);

  const optionsData = options(t);

  const LockTypeData = [
    {
      label: t('all'),
      value: '',
    },
    ...selectList,
  ];
  return (
    <>
      <PoolNavigation />

      <HistoricalBillView>
        <ModalViewBox
          width="1018px"
          marginTop="50px"
          select={
            <>
              <Title>{t('historical bill')}</Title>
              <SelectView>
                <SelevtItem>
                  {t('Types')}&nbsp;
                  <Select
                    isSearchable={false}
                    className="select-area"
                    options={optionsData}
                    defaultValue={optionsData[0]}
                    placeholder={t('Choose a pledge type')}
                    value={optionsData.find(
                      (item: any) => item.value === billType,
                    )}
                    onChange={(t: any) => {
                      setBillType(t.value);
                    }}
                  />
                </SelevtItem>
                &nbsp;
                <SelevtItem>
                  {t('Lock Type')}&nbsp;
                  <Select
                    isSearchable={false}
                    className="select-area"
                    options={LockTypeData}
                    defaultValue={LockTypeData[0]}
                    value={LockTypeData.find(
                      (item: any) => item.value === lockType,
                    )}
                    onChange={(t: any) => {
                      setLockType(t.value);
                    }}
                  />
                </SelevtItem>
              </SelectView>
            </>
          }
          title=""
        >
          <List
            dataSource={filter(list)}
            renderItem={(item: any) => (
              <HistoricalBillListItem data={item} key={item?.billId} />
            )}
            loading={loading}
            pagination={{
              pageSize: 10,
            }}
          />
        </ModalViewBox>
      </HistoricalBillView>
    </>
  );
};
export default HistoricalBill;
