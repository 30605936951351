import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TokenNum from '@/newPages/farms/component/TokenNum';
import dayjs from 'dayjs';
import { poolPledgeDit } from '@/newPages/Pool/pledge/hooks';
import ExtractModal from './extractModal';
import Modal from '@/new_components/Modal';
import { Button } from 'antd';
import { usePoolFactory } from '@/newPages/Pool/hooks';
import { useGetTokenType } from '../hooks';
import { Lplogo } from '@/newPages/Pool/component/AccordionList';
import { poolList } from '@/newPages/1230';
import { useGetpoolInfo } from '../hooks';
import {
  Item,
  BtnsView,
  BtnsViewBox,
  ItemTitle,
  IdTag,
  IdTagWrap,
} from '@/newPages/farms/historicalBill/item';
import { useGetbillInfo } from './hooks';

export const RedeemBtn = styled(BtnsView)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.primary1};
  color: ${({ theme }) => theme.text1};
`;

const RowData = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width:100%!important;
    display: flex !important;
  justify-content: space-between !important;
  flex-direction: inherit !important;
  `}
`;

interface IndexType {
  data: any;
}

const BtnBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-width: 224px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
   min-width: 100%;
  `};
`;

const RedeemContent = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.primary1};
  margin-bottom: 12px;
`;

const FooterBtnBox = (props: any) => {
  const { data, loading, redeemClick, extractClick } = props;
  const { t } = useTranslation();
  const poolData = useGetpoolInfo(data?.pid);

  const { billInfo } = useGetbillInfo(
    data.pid?.toString(),
    data.bid?.toString() ?? null,
  );

  console.log(data, 'data');

  return (
    <BtnsViewBox>
      <BtnBox>
        {/* {data.isRepeatOrder && ( */}
        <>
          {data.isDeposit && billInfo?.amount?.toString() > 0 ? (
            <>
              {(dayjs().unix() > data.lockEndTime ||
                (poolData &&
                  Number(poolData?.endTime) < 0 &&
                  Number(data?.billType) === 0)) && (
                <RedeemBtn
                  onClick={() => {
                    extractClick?.();
                  }}
                  style={{ marginRight: '10px' }}
                  disabled={loading}
                >
                  {t('redeem')}
                </RedeemBtn>
              )}
              <BtnsView
                onClick={() => {
                  redeemClick?.();
                }}
                disabled={loading}
              >
                {t('Eanrning')}
              </BtnsView>
            </>
          ) : (
            <BtnsView disabled>{t('Redeemed')}</BtnsView>
          )}
        </>
        {/* )} */}
      </BtnBox>
    </BtnsViewBox>
  );
};

const HistoricalBillListItem: React.FC<IndexType> = (props: IndexType) => {
  const { data } = props;
  const { t } = useTranslation();

  const [ExtractOrderData, setExtractOrderData] = useState<any>(null); // 提取收益数据/弹窗 itemdata
  const [RedeemData, setRedeemData] = useState<any>(null); // 赎回数据/弹窗

  const res = usePoolFactory();
  const { withdraw, loading } = res || {};

  const PoolTokenType = useGetTokenType();

  const getLp: any = (pid: number) => {
    return poolList[PoolTokenType]?.find(
      (item: any) => item.pid === Number(pid),
    );
  };
  const { endTime } = useGetpoolInfo(data?.pid) ?? {};

  const { billInfo } = useGetbillInfo(data.pid, data.bid ?? null);

  return (
    <Item>
      <IdTagWrap>
        <IdTag>
          {t('ID')}：{data?.billId}
        </IdTag>
      </IdTagWrap>

      <div>
        <Lplogo data={getLp(Number(data?.pid))} />
      </div>

      <RowData>
        <ItemTitle>{t('Billing amount')}</ItemTitle>
        <div>
          <b style={{ color: !data.isDeposit ? 'red' : '' }}>
            {/* <TokenNum
              address={res?.stakingToken ?? ''}
              num={data.singleAmount}
              icon={data.isDeposit ? '+' : '-'}
            /> */}
            <TokenNum
              address={res?.stakingToken ?? ''}
              num={billInfo?.amount?.toString()}
              icon={data.isDeposit ? '+' : '-'}
            />
            {PoolTokenType.toLocaleUpperCase()}
          </b>
        </div>
      </RowData>
      <RowData>
        <ItemTitle>{t('Lock Type')}</ItemTitle>
        <div>{poolPledgeDit(t)[data?.billType]}</div>
      </RowData>
      <RowData>
        <ItemTitle>{t('Billing type')}</ItemTitle>
        <div>{data.isDeposit ? t('pledge') : t('redemption')}</div>
      </RowData>
      <RowData>
        <ItemTitle>{t('lock time')}</ItemTitle>
        <div style={{ textAlign: 'left' }}>
          <div>
            {t('Start')}：
            {dayjs(data.lockStartTime * 1000).format('YYYY-MM-DD HH:mm')}
          </div>
          <div>
            {t('End')}：
            {Number(data?.billType) === 0
              ? Number(endTime) > 0
                ? endTime + ` ${t('Block')}`
                : t('The event is over')
              : dayjs(data.lockEndTime * 1000).format('YYYY-MM-DD HH:mm')}
          </div>
        </div>
      </RowData>
      <FooterBtnBox
        data={data}
        loading={loading}
        extractClick={() => setRedeemData(data)}
        redeemClick={() => setExtractOrderData(data)}
      />

      {ExtractOrderData !== null && (
        <ExtractModal
          isVisible={ExtractOrderData !== null}
          onClose={() => setExtractOrderData(null)}
          onClick={() => {
            withdraw?.(ExtractOrderData?.pid, ExtractOrderData?.bid);
          }}
          orderData={ExtractOrderData}
          loading={loading}
        />
      )}

      <Modal
        title={t('redeem')}
        width="411px"
        visible={RedeemData !== null}
        onCancel={() => setRedeemData(null)}
        footer={false}
      >
        <RedeemContent>
          {t(
            'Confirm the redemption of your principal, once redemption will no longer generate income',
          )}
          <p>
            {t('Billing amount')}:
            <TokenNum
              address={res?.stakingToken ?? ''}
              num={billInfo?.amount?.toString()}
              icon={data.isDeposit ? '+' : '-'}
            />
          </p>
        </RedeemContent>

        <Button
          type="primary"
          block
          size="large"
          disabled={loading}
          onClick={() => {
            if (RedeemData !== null) {
              withdraw?.(
                RedeemData?.pid,
                RedeemData?.bid,
                billInfo?.amount?.toString(),
              );
            }
          }}
        >
          {t('Confirm')}
        </Button>
      </Modal>
    </Item>
  );
};
export default HistoricalBillListItem;
