import React, { useState, useEffect, useCallback } from 'react';
import InvitationModal from '@/components/Modal';
import api from '@/utils/apiList';
import { useActiveWeb3React } from '@/hooks';
import useSWR, { mutate } from 'swr';
import { Flex } from 'rebass';
import { connect, useSelector } from 'react-redux';
import { INVITATION_ADD } from '@/state/Invitation/actions';
import CopyAddress from '@/components/CopyAddress/CopyAddress';
import { useTranslation } from 'react-i18next';
import { fiboAddressConvert } from '@/utils/fbAddress';
import CopyButton from '@/components/CopyAddress/Copy';
import Loading from '@/components/Loader';
import { toast } from 'react-toastify';
import {
  CloseColor,
  Main,
  Header,
  HeaderRow,
  ContentData,
  FooterBox,
  DataItem,
  InvitationInput,
  DataItemRow,
  CopyCodeBox,
  ExtractButton,
  Record,
  DataItemRowtTitle,
} from './styles';
import { ButtonPrimary } from '@/components/Button';
import Select from '@/components/Select';
import Balance from '@/newPages/farms/component/Balance';
import useSWRMutation from 'swr/mutation';

const tokenList: any = [
  {
    label: 'FIBO',
    icon: 'https://chainfb.oss-cn-hangzhou.aliyuncs.com/ETH/12306/FIBO.png',
    value: 'available_amount_fibo',
    api: 'fibo_extraction',
    symbol: 'FIBO-FUSD',
  },
  {
    label: 'FLY',
    icon:
      'https://chainfb.oss-cn-hangzhou.aliyuncs.com/ETH/12306/FLY_0x4FF286F0B97c2c578f35f99adbe3542fd14f042C.png',
    value: 'available_amount_fly',
    api: 'fly_extraction',
    symbol: 'FIBO-FLY',
  },
];

const LpType = [
  {
    label: 'FIBO-FUSD',
    value: 'FIBO-FUSD',
  },
  {
    label: 'FIBO-FLY',
    value: 'FIBO-FLY',
  },
];

const fetcher = async (url: string) => {
  const { data } = await fetch(url).then(res => res.json());
  return data;
};

interface IndexType {
  isShow: boolean;
  onDismiss?: () => any;
  dispatch: any;
}
const InvitationModalView = (props: IndexType) => {
  const InvitationData = useSelector((state: any) => state.Invitation);
  const { account } = useActiveWeb3React();
  const { t } = useTranslation();
  const [isVitationCode, setInvitationCode] = useState<string>(''); // 验证码/绑定
  const [loading, setLoading] = useState<boolean>(false);
  const { isShow, onDismiss } = props;
  const [myLptype, setMyLptype] = useState<string | number>(''); // 你的质押类型、选择
  const [InvitationRecordType, setInvitationRecordType] = useState<
    string | number
  >(''); // 邀请记录选择

  const [available_amountType, setAvailable_amountType] = useState<string>(
    tokenList[0].value,
  ); // 总收益类型

  // const [selectList, setSelectList] = useState<any>([]);

  const { data } = useSWR(
    () => (account ? api.getAddress(account).url : null),
    fetcher,
  ); // info

  const { data: projects } = useSWR(
    () => (account ? api.parent_address(account).url : null),
    fetcher,
  ); // 邀请记录列表

  const bindingUpdate = useCallback(
    (data: any, code) => {
      fetch(data.url, {
        method: data.method,
        body: JSON.stringify({
          address: account,
          invitation_code: code.arg,
        }),
      })
        .then(async (res: any) => {
          const data = await res.json();
          setTimeout(() => setLoading(false), 600);
          if (data.code === 200) {
            toast.success(t('Binding succeeded'), { autoClose: 3000 });
          } else {
            toast.error(data.msg, {
              autoClose: 2000,
            });
          }
          props?.dispatch({ type: INVITATION_ADD, value: data.data });
        })
        .catch(() => setLoading(false));
    },
    [isVitationCode],
  );

  // 提取
  const extract = (data: any) => {
    if (!account) return;
    fetch(data.url, {
      method: data.method,
      body: JSON.stringify({
        address: account,
      }),
    }).then(async (res: any) => {
      const data = await res.json();
      if (data?.code === 200) {
        toast.success(t('Succeeded in extraction'), { autoClose: 3000 });
        mutate(account ? api.getAddress(account).url : null).then(() => {
          props?.dispatch({ type: INVITATION_ADD, value: data });
          setLoading(true);
        });
      }
    });
  };

  const { trigger } = useSWRMutation(api.addAddress, bindingUpdate); // 绑定

  const { trigger: triggerExtract } = useSWRMutation(
    () =>
      available_amountType
        ? api.extraction_record(
            tokenList.find((item: any) => item.value === available_amountType)
              ?.api,
          )
        : null,
    extract,
  ); // 提取

  useEffect(() => {
    setInvitationRecordType(LpType[0].label);
    setMyLptype(LpType[0].label);
  }, []);

  useEffect(() => {
    if (data) {
      props?.dispatch({ type: INVITATION_ADD, value: data });
      // if (data?.pledge) {
      //   setSelectList(
      //     data?.pledge.map((item: any) => ({
      //       label: item.symbol + ' ' + t('farms pool'),
      //       value: item.symbol,
      //     })),
      //   );
      // }
    }
  }, [data]);

  const Confirm = () => {
    setLoading(true);
    trigger(isVitationCode);
  };

  const findLp = (data: any, type: string | number) => {
    return data.find((item: any) => item.symbol === type)?.pledge || '';
  };

  return (
    <InvitationModal
      isOpen={isShow}
      onDismiss={() => onDismiss?.()}
      maxHeight={100}
      maxWidth={!!InvitationData.address ? 794 : 325}
      overflow="none"
      {...props}
    >
      <div style={{ width: '100%' }}>
        <Header>
          <HeaderRow>
            {t(
              InvitationData.address
                ? 'Proceeds'
                : 'Please fill in the invitation code',
            )}
          </HeaderRow>
          <CloseColor onClick={() => onDismiss?.()} />
        </Header>
        <Main>
          {!InvitationData.address ? (
            <>
              <InvitationInput
                placeholder={t('Input')}
                value={isVitationCode}
                onChange={event => {
                  if (event.currentTarget.validity.valid) {
                    setInvitationCode(event.target.value.replace(/,/g, '.'));
                  }
                }}
                disabled={loading}
              />

              <Flex
                justifyContent="center"
                alignItems="center"
                style={{ height: '120px' }}
              >
                {loading && <Loading size="50px" />}
              </Flex>
              <ButtonPrimary
                width="100%"
                onClick={Confirm}
                disabled={!isVitationCode || loading}
              >
                {t('Confirm')}
              </ButtonPrimary>
            </>
          ) : (
            <>
              <ContentData>
                <Flex justifyContent="space-between">
                  <CopyCodeBox>
                    <CopyAddress
                      account={InvitationData.invitation_code}
                      defaultName={t('Your Referral Code')}
                    />
                  </CopyCodeBox>
                </Flex>
                <DataItem>
                  <DataItemRow>
                    <DataItemRowtTitle className="title">
                      {t('Invitees')}
                    </DataItemRowtTitle>
                    <div>{InvitationData.invitation_count}</div>
                  </DataItemRow>
                  {InvitationData?.parent_address && (
                    <DataItemRow>
                      <DataItemRowtTitle className="title">
                        {t('Referral Address')}
                      </DataItemRowtTitle>
                      <Flex alignItems="center" justifyContent="center">
                        {InvitationData.parent_address &&
                          fiboAddressConvert(InvitationData.parent_address)
                            .shortenAddress}
                        <CopyButton
                          toCopy={
                            fiboAddressConvert(InvitationData.parent_address)
                              .address
                          }
                        />
                      </Flex>
                    </DataItemRow>
                  )}
                  <DataItemRow>
                    <DataItemRowtTitle className="title">
                      {t('your pledge')}
                    </DataItemRowtTitle>

                    <Flex justifyContent="center" alignItems="center">
                      <Select options={LpType} onClick={setMyLptype} />
                      {data?.pledge && (
                        <Balance balance={findLp(data?.pledge, myLptype)} />
                      )}
                      LP
                    </Flex>
                  </DataItemRow>
                </DataItem>
              </ContentData>
              <FooterBox>
                <Flex alignItems="center">
                  <span>{InvitationData[available_amountType]}</span>
                  <Select
                    options={tokenList}
                    onClick={v => setAvailable_amountType(v.toString())}
                  />
                </Flex>
                <ExtractButton
                  disabled={
                    !(Number(InvitationData[available_amountType]) > 0) ||
                    loading
                  }
                  onClick={() => {
                    setLoading(true);
                    triggerExtract();
                  }}
                >
                  {t('extract')}
                </ExtractButton>
              </FooterBox>
            </>
          )}

          {data && (
            <Record>
              <div className="title flex-between">
                <div>{t('Referral Record')}</div>
                <div>
                  <Select options={LpType} onClick={setInvitationRecordType} />
                </div>
              </div>
              <header className="flex-between header">
                <div>{t('User address')}</div>
                <div>{t('Pledge amount')}</div>
              </header>
              <main className="content_list">
                {InvitationRecordType &&
                  projects &&
                  projects.length > 0 &&
                  projects.map((item: any) => {
                    return (
                      <div key={item.address_id} className="flex-between item">
                        <div>
                          {fiboAddressConvert(item.address).shortenAddress}
                        </div>
                        <div>
                          <Balance
                            balance={
                              findLp(item.pledge, InvitationRecordType) || ''
                            }
                          />
                          LP
                        </div>
                      </div>
                    );
                  })}
                {projects && projects.length === 0 && t('No record')}
              </main>
            </Record>
          )}
        </Main>
      </div>
    </InvitationModal>
  );
};
export default connect(state => state)(InvitationModalView);
