import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

const FarmsMenu = styled.div`
  display: flex;
  justify-content: center;
  color: #bfbfbf;
  font-size: 20px;
  margin: 22px 0;
  font-weight: 800;
  & > div {
    margin: 0 31px;
  }
`;
const FarmsMenuItem = styled.div<{ active: boolean }>`
  color: ${({ active, theme }) => (active ? theme.primary1 : '')};
`;

type item = {
  pathname: string;
  name: string;
};
const list = (t: any): item[] => [
  {
    pathname: '/farms',
    name: t('Farms'),
  },
  {
    pathname: '/farms/historicalBill',
    name: t('historical bill'),
  },
];
interface HeaderTabType {
  data?: item[];
}
const HeaderTab: React.FC<HeaderTabType> = (props: HeaderTabType) => {
  const { t } = useTranslation();
  const baseData = list(t);
  const { data = baseData } = props;

  const [pathname, setPathname] = useState<string>();
  const { push } = useHistory();
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  return (
    <FarmsMenu>
      {data.map((item: any) => {
        return (
          <FarmsMenuItem
            className="cursor"
            key={item.pathname}
            active={item.pathname == pathname}
            onClick={() => push(item.pathname)}
          >
            {item.name}
          </FarmsMenuItem>
        );
      })}
    </FarmsMenu>
  );
};
export default HeaderTab;
