import { useMemo } from 'react';
import { useActiveWeb3React } from '@/hooks';
import { useSingleResult } from '@/newPages/hooks';
import { usePoolFactoryContract } from '@/hooks/useContract';
import { poolFactoryType } from '@/constants';
import { useGetTokenType } from '../hooks';
import { Item } from '@/newPages/farms/historicalBill/item';

export const useGetBillList = (tokenNameType: poolFactoryType) => {
  const { account, chainId } = useActiveWeb3React();
  const PoolFactoryContract = usePoolFactoryContract(tokenNameType);
  const poolLength = useSingleResult(
    PoolFactoryContract,
    'getUserHistoryBill',
    [account, 1e10, 10000],
  );
  const setData = (data = []) => {
    return data
      .map((item: any) => ({
        ...item,
        billId: item.index?.toString(),
        timestamp: item.timestamp?.toString(),
        singleAmount: item.singleAmount?.toString(),
        lockStartTime: item.lockStartTime?.toString(),
        lockEndTime: item.lockEndTime?.toString(),
        billType: item.billType.toString(),
        bid: Number(item.bid),
        pid: Number(item.pid),
      }))
      .filter(item => item.singleAmount && item.isDeposit);
  };

  const setRepeatOrder = (data: any[]) => {
    // data.map(item => {
    //   if (Number(item?.billType) === 0) {
    //     const newList: any[] = [];
    //     // 周期订单归类
    //     data.map(items => {
    //       if (
    //         Number(items?.billType) === 0 &&
    //         item.bid === items.bid &&
    //         item.pid === items.pid
    //       ) {
    //         newList.push(items);
    //       }
    //     });
    //     if (item.billId === newList[0].billId) {
    //       item.isRepeatOrder = true;
    //     }
    //   } else {
    //     item.isRepeatOrder = true;
    //   }
    // });

    const newData: any = [];
    data.map(item => {
      if (Number(item?.billType) === 0) {
        const newList: any[] = [];
        // 周期订单归类
        data.map(items => {
          if (
            Number(items?.billType) === 0 &&
            item.bid === items.bid &&
            item.pid === items.pid
          ) {
            newList.push(items);
          }
        });
        if (item.billId === newList[0].billId) {
          item.isRepeatOrder = true;
          newData.push(item);
        }
      } else {
        newData.push(item);
      }
    });
    return newData;
  };
  return useMemo(() => {
    if (!chainId || !account) return undefined;
    if (!poolLength) {
      return {
        list: [],
        loading: true,
      };
    }
    return {
      list: setRepeatOrder(setData(poolLength)),
      loading: !!!poolLength,
    };
  }, [account, chainId, poolLength]);
};

export const useGetbillInfo = (pid: number, orderId: number | null) => {
  const TokenName = useGetTokenType();
  const { account } = useActiveWeb3React();
  const PoolFactoryContract = usePoolFactoryContract(TokenName);
  const billInfo = useSingleResult(PoolFactoryContract, 'userInfo', [
    pid,
    account,
    orderId,
  ]);
  const pending = useSingleResult(PoolFactoryContract, 'pending', [
    pid,
    account,
    orderId,
  ]);

  return {
    billInfo,
    pending: pending?.toString(),
  };
};
