import styled from 'styled-components';
import { ExternalLink } from '@/theme';

const Link = styled(ExternalLink)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.primary3};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      margin-bottom: 10px;
  `};
`;

const Item = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  display: flex;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.primary1};

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;
const Title = styled.div`
  display: flex;
  color: ${({ theme }) => theme.text2};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-start!important;
  `};
  margin-bottom: 5px;
`;

const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  padding: 22px 0;
  text-align: center;
  flex-wrap: wrap;
  font-weight: 800;
  /* font-weight: bold; */
  position: relative;
  transition: all 0.1s;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: space-between;
    font-size:14px;
    padding: 13px 19px;
    &>div:nth-child(-n + 2){
      width:50%!important;
      flex:none;
    }
  `};
  & > div {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    /* align-items: flex-start; */
    align-items: center;
    justify-content: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      flex: none!important;
      margin-bottom: 10px;
    `};
    flex-direction: column;
    /* & > div {
      flex: 1;
      justify-content: center;
    } */
  }
`;
const AccordionContent = styled.div<{ Stow: boolean }>`
  width: 100%;
  background: ${({ theme }) => theme.primary5};
  height: ${({ Stow }) => (Stow ? 'fit-content' : '0')};
  padding: ${({ Stow }) => (Stow ? '18px 35px' : '0 35px')};
  transition: height 0.2s;
  overflow: hidden;
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  ${({ theme, Stow }) => theme.mediaWidth.upToSmall`
  padding: ${Stow ? '18px 20px' : '0 35px'};
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column-reverse;
    &>div{
      margin:0;
      width:100%;
    }
  `};
`;

const AccordionContentBox = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.primary5};
  border-radius: 12px;
  border: 1px solid #cde4e4;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0 10px 61px;
  height: 100%;
  padding: 11px 15px;
  box-sizing: border-box;
  & > bottom {
    height: 36px;
  }
`;
const AccordionContentBoxTitle = styled.strong`
  display: inline-block;
  margin-bottom: 12px;
`;

const DetailsBtn = styled(Title)`
  color: #1890ff;
  align-items: center !important;
`;

const RowRprView = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none!important;
  `};
`;

const HeaderAPR = styled(Title)`
  display: none !important;
  text-align: right;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block!important;

    &>div{
      display: inline-block;
    }
    & > div:last-child {
      color: ${({ theme }) => theme.primary1};
    }
  `};
`;

const BlockReward = styled.div`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none!important;
  `};
`;

const AccordionContentBlockReward = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.primary3};
  display: none;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  display: block;
    & > div{
      display:flex;
      justify-content:space-between;
      margin-top:19px;
    }
  `};
`;

const LpTokenName = styled.div`
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  white-space: nowrap;
  justify-content: center;
  align-items: center !important;
  z-index: 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: start !important;
  `};
  & > span {
    width: 88px;
    text-align: left;
  }
`;

export {
  DetailsBtn,
  Link,
  Item,
  Title,
  HeaderContent,
  AccordionContent,
  AccordionContentBox,
  AccordionContentBoxTitle,
  RowRprView,
  HeaderAPR,
  BlockReward,
  AccordionContentBlockReward,
  LpTokenName,
};
