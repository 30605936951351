import { useState, useEffect, useMemo } from 'react';
import { useSingleResult, useFarmsMessage } from '@/newPages/hooks';
import { mappingToken as MAPPINGTOKEN } from '@/constants';
import { useMappingContract, useErcContract } from '@/hooks/useContract';
import { useActiveWeb3React } from '@/hooks';
import { useTokenContract } from '@/hooks/useContract';
import { BigNumber } from 'bignumber.js';
import { ETHER } from 'flyswap-skdv1';
import { isFIBO } from '@/newPages/1230';
import { maxUint256Value } from '@/constants';

const mappingToken = MAPPINGTOKEN;

export const useLpMappingInfo = (mappingTokenData?: any) => {
  const { account, library } = useActiveWeb3React();
  const [loading, setLoading] = useState(false);
  const tokenContract = useTokenContract(mappingTokenData.from_token); // lp合约
  // useSingleResultuseSingleResult
  const allowance = useSingleResult(
    tokenContract,
    'allowance',
    [account, mappingToken],
    loading,
  )?.toString();
  const mappingContract = useMappingContract(); // farms合约

  const getErcContract = useErcContract();
  const { orderMessage: Message, warning, toast } = useFarmsMessage();

  const isFIBOToken = isFIBO({ symbol: mappingTokenData.to_symbol });

  // message
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const orderMessage = (hash: string, fn = () => {}) => {
    Message(hash, () => {
      setLoading(false);
      fn?.();
    });
  };

  // 开启
  const open = async () => {
    const from_token = mappingTokenData.from_token;
    const contract = getErcContract(from_token);
    const toastId = toast.loading('上链中...');
    setLoading(true);
    try {
      const res = await contract?.approve(mappingToken, maxUint256Value);
      if (res?.hash) {
        orderMessage(res.hash);
        toast.done(toastId);
      }
    } catch (error) {
      setLoading(false);
      toast.done(toastId);
    }
  };

  const mappingTokens = async (
    fromValue: string,
    balance?: any,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fn = () => {},
  ) => {
    if ((!mappingContract && !library) || !account) return;
    if (fromValue === '0') {
      warning('请输入金额');
      return;
    }
    if (
      BigNumber(fromValue)
        .comparedTo(
          BigNumber(mappingTokenData.from_balanceOf).div(
            Math.pow(10, mappingTokenData.from_decimals),
          ),
        )
        .toString() === '1'
    ) {
      warning('余额不足');
      return;
    }

    if (
      Number(fromValue) >
      (mappingTokenData.to_balance ?? balance) /
        Math.pow(10, mappingTokenData.to_decimals)
    ) {
      warning(`当前${mappingTokenData.to_symbol}流动性不足`);
      return;
    }

    const amount = BigNumber(fromValue)
      .times(Math.pow(10, mappingTokenData.from_decimals))
      .toFixed();

    const toastId = toast.loading('上链中...');
    setLoading(true);
    try {
      console.log('try');
      const res = await mappingContract?.mappingToken(
        mappingTokenData.from_token,
        isFIBOToken
          ? '0x0000000000000000000000000000000000000000'
          : mappingTokenData.to_token,
        amount,
      );
      if (res?.hash) {
        orderMessage(res.hash, fn);
        toast.done(toastId);
      }
    } catch (error) {
      console.log('catch');
      setLoading(false);
      toast.done(toastId);
    }
  };

  // fu 兑换内嵌套映射
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const FUmappingTokens = async (fromValue: string, fn = () => {}) => {
    let Balance: any;
    if (isFIBOToken) {
      Balance = await library?.getBalance(mappingToken);
    } else {
      const erc20ToToken = getErcContract(mappingTokenData.to_token);
      Balance = await erc20ToToken?.balanceOf(mappingToken);
    }
    mappingTokens(fromValue, Balance.toString(), fn);
  };

  return useMemo(() => {
    if (!account) return undefined;
    if (!allowance) {
      return {};
    }
    return {
      allowance,
      open,
      loading,
      mappingTokens,
      FUmappingTokens,
    };
  }, [
    account,
    allowance,
    loading,
    mappingTokenData,
    open,
    mappingTokens,
    FUmappingTokens,
  ]);
};

// list
export const useLpMappingList = () => {
  const { account, library } = useActiveWeb3React();
  const contract = useMappingContract(); // farms合约
  const getPairs = useSingleResult(contract, 'getPairs');
  const getErcContract = useErcContract();
  const [list, setList] = useState<any>([]);
  const [loading, setloading] = useState<boolean>(false);

  useEffect(() => {
    if (!getPairs || loading) return;
    if (getPairs?.length === 0) {
      setloading(false);
      return;
    }
    (async () => {
      setloading(true);
      const newData = [];
      for (let index = 0; index < 2; index++) {
        const item = getPairs[index];
        const erc20FromToken = getErcContract(item?.fromToken);
        // from
        const [
          mappingRate,
          from_symbol,
          from_decimals,
          from_balanceOf,
          from_allowance,
          from_balanceOfMapping,
          Balance,
        ]: any = (
          await Promise.all([
            // 汇率
            contract?.mappingRates(item.fromToken, item.toToken),
            erc20FromToken?.symbol(),
            erc20FromToken?.decimals(),
            erc20FromToken?.balanceOf(account),
            erc20FromToken?.allowance(account, mappingToken),
            erc20FromToken?.balanceOf(mappingToken),
            library?.getBalance(mappingToken),
          ])
        ).map((item: any) => {
          if (typeof item !== 'string') {
            return item.toString();
          }
          return item;
        });

        // to
        let toData: any = {};
        if (item.toToken === '0x0000000000000000000000000000000000000000') {
          toData = {
            to_balance: Balance,
            to_symbol: ETHER.symbol,
            to_decimals: ETHER.decimals,
          };
        } else {
          const erc20ToToken = getErcContract(item.toToken);
          const [to_symbol, to_decimals, to_balance] = (
            await Promise.all([
              erc20ToToken?.symbol(),
              erc20ToToken?.decimals(),
              erc20ToToken?.balanceOf(mappingToken),
            ])
          ).map((item: any) => {
            if (typeof item !== 'string') {
              return item.toString();
            }
            return item;
          });

          toData = {
            to_symbol,
            to_decimals,
            to_balance,
          };
        }
        newData.push({
          value: `${item.fromToken}_${item.toToken}`,
          from_token: item.fromToken,
          to_token: item.toToken,
          mappingRate,
          from_symbol,
          from_decimals,
          from_balanceOf,
          from_allowance,
          from_balanceOfMapping,
          label: `${from_symbol} --- ${toData.to_symbol}`,
          ...toData,
        });
      }
      setList(newData);
      setloading(false);
    })();
  }, [getPairs]);

  return useMemo(() => {
    return { list, loading };
  }, [loading, list]);
};
