import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  HeaderContent,
  Title,
} from '@/newPages/farms/list/component/AccordionList/styles';
import TokenNum from '@/newPages/farms/component/TokenNum';
import dayjs from 'dayjs';
import { ButtonPrimary, ButtonOutlined } from '@/components/Button';

export const Item = styled(HeaderContent)`
  white-space: nowrap;
  font-size: 14px;
  line-height: 21px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 14px;
  color: ${({ theme }) => theme.primary1};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 13px 19px;
    &>div:nth-child(-n + 2){
      width:auto!important;
    }
    &>div:nth-child(-n + 1){
      width:100%!important;
      flex:none;
      margin-bottom:12px;
    }
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 12px;
  `};
`;

interface IndexType {
  data: any;
}
export const BtnsView = styled(ButtonPrimary)`
  height: 35px;
  min-width: 97px;
  border-radius: 18px;
  font-size: 12px;
  z-index: 0;
  width: auto;
  padding: 0;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `};
`;

export const BtnsViewBox = styled.div`
  align-items: center !important;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `};
`;

export const ItemTitle = styled(Title)`
  font-size: 14px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
   &>div:last-child {
    font-size: 12px;
  }
  `};
`;

export const IdTag = styled.div`
  width: 110px;
  height: 36px;
  background-color: ${({ theme }) => theme.bg4};
  border-radius: 0px 18px 18px 0px;
  flex: none !important;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    left:0;
    top: 0;
    transform: translateY(0%);
    left: -19px;
  `};
`;
export const IdTagWrap = styled.div`
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    height:36px;
  `};
`;
const HistoricalBillListItem: React.FC<IndexType> = (props: IndexType) => {
  const { data } = props;
  const { push } = useHistory();
  const { t } = useTranslation();

  return (
    <Item>
      <IdTagWrap>
        <IdTag>
          {t('ID')}：{data?.billId}
        </IdTag>
      </IdTagWrap>
      <div>
        <ItemTitle>{t('the amount')}</ItemTitle>
        <div>
          <b style={{ color: !data.isDeposit ? 'red' : '' }}>
            <TokenNum
              address={data.lpToken}
              num={data.singleAmount}
              icon={data.isDeposit ? '+' : '-'}
            />
          </b>
        </div>
      </div>
      <div>
        <ItemTitle>{t('Types')}</ItemTitle>
        <div>{data.isDeposit ? t('pledge') : t('redemption')}</div>
      </div>
      <div>
        <ItemTitle>{t('lock time')}</ItemTitle>
        <div>
          <div>
            {t('Start')}：
            {dayjs(data.lockStartTime * 1000).format('YYYY-MM-DD HH:mm')}
          </div>
          <div>
            {t('End')}：
            {dayjs(data.lockEndTime * 1000).format('YYYY-MM-DD HH:mm')}
          </div>
        </div>
      </div>
      <BtnsViewBox>
        <BtnsView
          onClick={() =>
            push(`/farms/pledge/redeem/${data?.pId?.toString()}/${data.billId}`)
          }
        >
          {t('Eanrning')}
        </BtnsView>
      </BtnsViewBox>
    </Item>
  );
};
export default HistoricalBillListItem;
