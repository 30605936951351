import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StyledMenuButton } from '@/components/Settings';
import { useHistory, useLocation } from 'react-router-dom';
import SwapImg from '@/assets/images/TabBar/swap.png';
import SwapActiveImg from '@/assets/images/TabBar/swap_active.png';
import Farms_img from '@/assets/images/TabBar/farms.png';
import Farms_active_img from '@/assets/images/TabBar/farms_active.png';
import { Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { poolFactory } from '@/constants';
import pool_img from '@/assets/images/TabBar/pool.png';
import pool_active_img from '@/assets/images/TabBar/pool_active.png';

const MenuItem = (props: any) => {
  const { replace } = useHistory();
  const location = useLocation();
  const [defaultOpenKeys, setDefaultSelectedKeys] = useState<string>('');
  useEffect(() => {
    setDefaultSelectedKeys(location.pathname?.replace('#', ''));
  }, [location]);
  const { data, onClick } = props;
  if (!data.length) return <></>;
  return (
    <Menu selectedKeys={[defaultOpenKeys]}>
      {data.map((item: any) => {
        return (
          <Menu.Item
            key={item.path}
            onClick={() => {
              replace(item.path);
              onClick?.();
            }}
          >
            {item.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

const MenuView = styled.div`
  display: flex;
  padding: 0 16px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0;
    height: 64px;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.bg1};
    border-top: 1px solid ${({ theme }) => theme.bg2};
    // transition: all 0.2s;
  `};
`;

const MenuBtn = styled(StyledMenuButton)<{ active: boolean }>`
  height: 100%;
  padding: 0 16px;
  font-size: 16px;
  white-space: nowrap;
  color: ${({ theme, active }) => (active ? theme.primary1 : theme.text3)};
  font-weight: ${({ active }) => (active ? 700 : 500)};
  :hover,
  :focus {
    background-color: transparent;
  }

  & > div {
    width: 100%;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 12px;
  `};
`;

const Icon = styled.img`
  display: none;
  width: 28px;
  height: 28px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
  `};
  margin: auto;
`;

interface IndexType {
  isVisible?: boolean;
  onClose?: () => any;
}
type itemType = {
  name: string;
  path: string;
  img: any;
  active_img: any;
  icon?: any;
  children?: { name: string; path: string }[];
};

const list = (t: any): itemType[] => {
  return [
    {
      name: 'swap',
      path: '/swap',
      img: SwapImg,
      active_img: SwapActiveImg,
    },
    {
      name: 'Farms',
      path: '/farms',
      img: Farms_img,
      active_img: Farms_active_img,
    },
    // {
    //   name: 'Cross chain bridge',
    //   path: 'cross_chain bridge',
    //   img: cross_chain_bridge_img,
    //   active_img: cross_chain_bridge_img,
    //   icon: <MenuIcon size={15} />,
    // },
    {
      name: 'Pool',
      path: '/farms/Pool',
      img: pool_img,
      active_img: pool_active_img,
      children: Object.entries(poolFactory).map(item => {
        return {
          name: `${item[0].toLocaleUpperCase()} ${t('Zone')}`,
          path: `/pool/${item[0]}`,
        };
      }),
    },
  ];
};

const Index: React.FC<IndexType> = () => {
  const { t } = useTranslation();

  const [activeKey, setActiveKey] = useState('');
  const { replace } = useHistory();
  const location = useLocation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setActiveKey(location.pathname);
  }, [location]);

  const click = (item: itemType) => {
    if (item.path === 'cross_chain bridge') {
      window.open(
        'https://jingyan.baidu.com/article/49ad8bce9dfb9c5835d8fa71.html',
      );
    } else {
      replace(item.path);
    }
  };

  return (
    <MenuView>
      {list(t).map((item: itemType) => (
        <MenuBtn key={item.path} active={activeKey === item.path}>
          <div
            className="flex-item-center"
            style={{ justifyContent: 'center' }}
          >
            {item?.children && item?.children?.length > 0 ? (
              <Dropdown
                overlay={
                  <MenuItem
                    data={item?.children}
                    onClick={() => setVisible(false)}
                  />
                }
                visible={visible}
              >
                <span onClick={() => setVisible(!visible)}>
                  <Icon
                    src={
                      item?.children.map(item => item.path).includes(activeKey)
                        ? item.active_img
                        : item.img
                    }
                  />
                  {t(item.name)}
                  {item.icon}
                </span>
              </Dropdown>
            ) : (
              // <Dropdown overlay={menu}>
              //   <a
              //     className="ant-dropdown-link"
              //     onClick={e => e.preventDefault()}
              //   >
              //     Hover me
              //   </a>
              // </Dropdown>
              <span
                onClick={() => {
                  click(item);
                }}
              >
                <Icon
                  src={activeKey === item.path ? item.active_img : item.img}
                />
                {t(item.name)}
                {item.icon}
              </span>
            )}
          </div>
        </MenuBtn>
      ))}
    </MenuView>
  );
};
export default Index;
