import { ChainId, Token, ETHER } from 'flyswap-skdv1';
import { poolFactoryType } from '@/constants';

export const USDT = new Token(
  ChainId.FIBO,
  '0xAF9455E943797928be2D6CBb010b96D662d2C35E', //'0xAF9455E943797928be2D6CBb010b96D662d2C35E',
  18,
  'USDT',
  'USDT',
);

export const FLY = new Token(
  ChainId.FIBO,
  '0x4FF286F0B97c2c578f35f99adbe3542fd14f042C',
  8,
  'FLY',
  'Fly Swap Token',
);

export const FUSDToken = '0xbb1d5c55ce373943a571c68da6e7df0610254e2d';
export const FUSD = new Token(ChainId.FIBO, FUSDToken, 18, 'FUSD', 'F USDT');

const WFIBO = new Token(
  ChainId.FIBO,
  '0xe3DE253AA18D949273aFbCa44053ecB66424Ac4a', //'0xAF9455E943797928be2D6CBb010b96D662d2C35E',
  18,
  'FIBO',
  'Wrapped Fibo',
);

export const FFIBO = new Token(
  ChainId.FIBO,
  '0x2Df800f6D6185324dAfeD4544133fa2F87deCD59', //'0xAF9455E943797928be2D6CBb010b96D662d2C35E',
  18,
  'FFIBO',
  'Wrapped FFIBO',
);

export const FFLY = new Token(
  ChainId.FIBO,
  '0x0546993878BD95000cba38082FBB961abDbC3ebA', //'0xAF9455E943797928be2D6CBb010b96D662d2C35E',
  18,
  'FFLY',
  'Wrapped FFLY',
);

const uList = [FUSD.address, USDT.address]; // U.FU

export const isFIBO = (token: any) => {
  return token?.symbol === ETHER.symbol;
};
export const isMappingType = (tokenA: any, tokenB: any) => {
  if (
    (uList.includes(tokenA?.address) && uList.includes(tokenB?.address)) ||
    (tokenA?.address === FFIBO.address && isFIBO(tokenB)) ||
    (tokenA?.address === FFLY.address && tokenB?.address === FLY.address)
  ) {
    return true;
  } else {
    return false;
  }
};

export type farmsType = {
  pid: number;
  lpSymbol: string;
  // lpToken?: string;
  quoteToken?: Token;
  token?: Token;
};

export const farmsList: farmsType[] = [
  // {
  //   pid: 0,
  //   lpSymbol: 'FIBO-FLY',
  //   lpToken: '0x4FF286F0B97c2c578f35f99adbe3542fd14f042C',
  //   quoteToken: WFIBO,
  //   token: FLY,
  // },
  {
    pid: 1,
    lpSymbol: 'FIBO-FUSD',
    // lpToken: '0x3725c9892ef29044BCef2D7B8790536cF8005d93',
    quoteToken: WFIBO,
    token: FUSD,
  },
  {
    pid: 2,
    lpSymbol: 'FLY-FIBO',
    // lpToken: '0x3368c65c435937FC4748920BFCAC43A33054D9d7',
    quoteToken: FLY,
    token: WFIBO,
  },
].map(p => ({ ...p }));

export const poolList: Record<poolFactoryType, any[]> = {
  // fibo: 0x2219845942d28716c0f7c605765fabdca1a7d9e0
  fibo: [
    {
      pid: 0,
      quoteToken: USDT,
      token: WFIBO,
    },
    // {
    //   pid: 1,
    //   quoteToken: WFIBO,
    //   token: WFIBO,
    // },
  ],
  // fly: 0xc0ef448d091bdbccEC0581448e760C8A8EcC4B5a
  fly: [
    {
      pid: 0,
      quoteToken: USDT,
      token: FLY,
    },
    // {
    //   pid: 1,
    //   quoteToken: WFIBO,
    //   token: FLY,
    // },
  ],
};
