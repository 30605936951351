import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import styled from 'styled-components';

type IndexType = ModalProps;

const ModalView = styled(Modal)`
  .ant-modal-content {
    border-radius: 22px;
    overflow: hidden;
  }

  .ant-modal-title {
    text-align: center;
    color: ${({ theme }) => theme.primary1};
    font-size: 22px;
    font-weight: bold;
  }
  .ant-modal-close {
    color: ${({ theme }) => theme.primary1};
  }
`;

const Index: React.FC<IndexType> = props => {
  return <ModalView {...props} />;
};
export default Index;
