import { useState, useMemo } from 'react';
import { useActiveWeb3React } from '@/hooks';
import { useMsterChefContract } from '@/hooks/useContract';
import { useTokenContract } from '@/hooks/useContract';
import { BigNumber } from 'bignumber.js';
import { farmsType } from '@/newPages/1230';
import { masterChef as MASTERCHEF } from '@/constants';
import { toast } from 'react-toastify';
import { maxUint256Value } from '@/constants';
import { useSingleResult, useBalance, useFarmsMessage } from '@/newPages/hooks';

// extends farmsType
export interface LpInfoType extends farmsType {
  allowance?: boolean | string;
  decimals: number;
  balanceOf: string;
  allocPoint?: string;
  multiplier?: any;
  isOpen?: boolean;
  symbol?: string;
  Balance?: string;
  userTicket?: any;
  loading?: boolean;
  lpToken: string;
  // poolTicketSupply: string;
}

const LpInfo: LpInfoType = {
  allowance: '',
  decimals: 18,
  balanceOf: '',
  allocPoint: '',
  multiplier: '',
  isOpen: false,
  symbol: '',
  Balance: '',
  pid: 0,
  lpSymbol: '',
  // lpToken: '',
  userTicket: '',
  loading: true,
  // poolTicketSupply: '',
  lpToken: '',
};

// lp info
export const useGetLp = (lp: farmsType): LpInfoType | undefined | null => {
  const { account, chainId } = useActiveWeb3React();
  const contract = useMsterChefContract(); // farms合约
  const poolInfo = useSingleResult(contract, 'poolInfo', [lp?.pid]);
  const tokenContract = useTokenContract(poolInfo?.lpToken); // lp合约
  const lpProAResult = useSingleResult(contract, 'poolInfo', lp.pid); // 指定 pid 的池子信息

  const flyPerBlock = useSingleResult(contract, 'flyPerBlock')?.toString(); // 每块基础 FFLY 产量
  const fiboPerBlock = useSingleResult(contract, 'fiboPerBlock')?.toString(); // 获取每块基础 FFIBO 产量

  // const fiboPerBlock = useSingleResult(contract, 'fiboPerBlock')?.toString(); // 获取每块基础 FFIBO 产量

  const totalAllocPoint = useSingleResult(
    contract,
    'totalAllocPoint',
  )?.toString(); // 获取农场总分配点数

  const BONUS_MULTIPLIER = useSingleResult(
    contract,
    'BONUS_MULTIPLIER',
  )?.toString(); // 每块的加权乘数

  const userFlyRewardInLp = useSingleResult(contract, 'userFlyRewardInLp', [
    lp.pid,
    account,
  ])?.toString(); // 获取用户在指定 pool id 下的总 FFLY 收益

  const userFiboRewardInLp = useSingleResult(contract, 'userFiboRewardInLp', [
    lp.pid,
    account,
  ])?.toString(); // 获取用户在指定 pool id 下的总 FFiBo 收益

  const allowance = useSingleResult(tokenContract, 'allowance', [
    account,
    MASTERCHEF,
  ])?.toString();

  const userTicket = useSingleResult(contract, 'userTicket', [
    lp.pid,
    account,
  ])?.toString(); // 投入的票数

  // 获取指定 pid 的池子总票数
  const poolTicketSupply = useSingleResult(contract, 'getPoolTicketSupply', [
    lp.pid,
  ]);

  const { balanceOf, decimals, Balance, lpBalanceOf } =
    useBalance(poolInfo?.lpToken) || {};
  return useMemo(() => {
    if (!chainId) return undefined;
    if (
      !lpProAResult ||
      // !userPendingResult ||
      !flyPerBlock ||
      !BONUS_MULTIPLIER ||
      !decimals ||
      !balanceOf ||
      !allowance ||
      !Balance ||
      !userFlyRewardInLp ||
      !userFiboRewardInLp ||
      !lpBalanceOf ||
      !poolTicketSupply ||
      !userTicket ||
      !poolInfo
    ) {
      return LpInfo;
    }

    const allocPoint = lpProAResult?.allocPoint?.toString();
    return {
      ...lp,
      decimals,
      balanceOf,
      allocPoint,
      allowance,
      // 块奖励
      multiplier: BigNumber(flyPerBlock)
        .times(BONUS_MULTIPLIER)
        .times(allocPoint)
        .div(totalAllocPoint),
      // fibo 块奖励
      multiplierFIBO: BigNumber(fiboPerBlock)
        .times(BONUS_MULTIPLIER)
        .times(allocPoint)
        .div(totalAllocPoint),
      Balance,
      symbol: lp.lpSymbol,
      isOpen: allowance !== '0',
      userFlyRewardInLp,
      userFiboRewardInLp,
      lpBalanceOf,
      poolTicketSupply,
      flyPerBlock,
      fiboPerBlock,
      userTicket: userTicket, //BigNumber(userTicket).div(Math.pow(10, decimals)),
      lpToken: poolInfo?.lpToken,
      // .toString(),
    };
  }, [
    Balance,
    chainId,
    lp,
    // userPendingResult,
    lpProAResult,
    flyPerBlock,
    BONUS_MULTIPLIER,
    decimals,
    balanceOf,
    allowance,
    userFlyRewardInLp,
    userFiboRewardInLp,
    // fiboPerBlock,
    totalAllocPoint,
    fiboPerBlock,
    lpBalanceOf,
    poolTicketSupply,
    userTicket,
  ]);
};

// eslint-disable-next-line @typescript-eslint/class-name-casing
interface useFarmsApiType {
  redeem: () => any;
  profit: () => any;
  pledge: () => any;
  submitting: boolean;
}

// farm api
export function useFarmsApi(lp: farmsType, useBillInfo?: any): any | undefined {
  const { account, chainId } = useActiveWeb3React();
  const masterChef: any = useMsterChefContract();
  const poolInfo = useSingleResult(masterChef, 'poolInfo', [lp?.pid]);
  const { Balance } = useBalance(poolInfo?.lpToken) || {};
  const tokenContract = useTokenContract(poolInfo?.lpToken); // lp合约
  const [loading, setLoading] = useState(false);
  const decimals = useSingleResult(tokenContract, 'decimals'); // 精度

  const { orderMessage: Message, warning: notify } = useFarmsMessage();

  // message
  const orderMessage = (hash: string) => {
    Message(hash, () => {
      setLoading(false);
    });
  };

  const setTokenPledge = async (action: string, parms: any) => {
    setLoading(true);
    const toastId = toast.loading('上链中...');
    // const nonce = await library?.getTransactionCount(currentAccount || '')
    try {
      const res = await masterChef[action](...parms);
      if (res?.hash) {
        toast.done(toastId);
        orderMessage(res.hash);
      }
    } catch (error) {
      const err: any = error;
      if (
        err.code === -32603 &&
        err.data &&
        err.data.code === -32000 &&
        err.data.message.indexOf('locked') !== -1
      ) {
        notify('账单未到期');
      }
      toast.done(toastId);
      setLoading(false);
    }
  };
  // 质押 金额，时间类型
  const pledge = (depositValue: string, depositType: number) => {
    if (Number(depositValue) > Number(Balance)) {
      notify('余额不足');
      return;
    }
    if (!decimals) {
      notify('精度');
      return;
    }

    const action: any = lp.pid === 0 ? 'enterStaking' : 'deposit';
    const amount = BigNumber(depositValue)
      .times(Math.pow(10, decimals))
      .toFixed(0);

    const parms: any =
      lp.pid === 0 ? [depositType, amount] : [lp.pid, depositType, amount];

    setTokenPledge(action, parms);
  };

  // 赎回
  const redeem = (withdrawValue: string, withdrawBillId: string) => {
    if (!useBillInfo) {
      notify('暂无账单信息');
      return;
    }
    if (withdrawValue === '0') {
      notify('请输入赎回金额');
      return;
    }
    if (
      BigNumber(withdrawValue)
        .times(Math.pow(10, decimals))
        .isGreaterThan(useBillInfo.amount)
    ) {
      notify('可取金额不足');
      return;
    }
    const action = lp.pid === 0 ? 'leaveStaking' : 'withdraw';
    const amount = BigNumber(withdrawValue)
      .times(Math.pow(10, decimals))
      .toFixed(0);
    const parms =
      lp.pid === 0
        ? [withdrawBillId, amount]
        : [lp.pid, withdrawBillId, amount];
    setTokenPledge(action, parms);
  };

  // 赎回
  const handleGogogo = (withdrawBillId: string) => {
    if (!useBillInfo) {
      notify('暂无账单信息');
      return;
    }
    setTokenPledge('emergencyWithdraw', [lp.pid, withdrawBillId]);
  };

  // 收益
  const profit = (withdrawBillId: string) => {
    if (!useBillInfo) {
      notify('暂无账单信息');
      return;
    }
    const action = lp.pid === 0 ? 'leaveStaking' : 'withdraw';
    const parms = [lp.pid, withdrawBillId, 0];
    setTokenPledge(action, parms);
  };
  return useMemo(() => {
    if (!chainId || !account) return undefined;
    if (!Balance) return null;
    return {
      handleGogogo,
      redeem,
      profit,
      pledge,
      loading,
    };
  }, [
    lp,
    account,
    chainId,
    useBillInfo,
    Balance,
    loading,
    decimals,
    handleGogogo,
  ]);
}

export const useOpenFarms = (lp: farmsType) => {
  const { account, chainId } = useActiveWeb3React();

  const contract = useMsterChefContract(); // farms合约
  const poolInfo = useSingleResult(contract, 'poolInfo', [lp?.pid]);

  const tokenContract = useTokenContract(poolInfo?.lpToken); // lp合约
  const [loading, setLoading] = useState(false);
  const { orderMessage: Message } = useFarmsMessage();

  const allowance = useSingleResult(tokenContract, 'allowance', [
    account,
    MASTERCHEF,
  ])?.toString();

  // 开启
  const openFarms = async () => {
    setLoading(true);
    const toastId = toast.loading('上链中...');
    try {
      const res = await tokenContract?.approve(MASTERCHEF, maxUint256Value);
      if (res?.hash) {
        toast.done(toastId);
        Message(res?.hash, () => {
          setLoading(false);
        });
      }
    } catch (error) {
      toast.done(toastId);
      setLoading(false);
    }
  };
  return useMemo(() => {
    if (!chainId || !account) return undefined;
    if (!allowance) return null;
    return {
      isOpen: allowance !== '0',
      openFarms,
      loading,
    };
  }, [lp, account, chainId, allowance, loading]);
};
